import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
import Tooltip from "@mui/material/Tooltip";
import { Content } from "src/quill";
import { post, comu, repl } from "src/proto/code_pb";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { CardActionArea } from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ReplyIcon from "@mui/icons-material/Reply";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import ShareIcon from "@mui/icons-material/Share";
import MenuIcon from "@mui/icons-material/Menu";
import { SyledCard, StyledTypography, SyledCardContent } from "./style";

export function PVote(item: post) {
  function f() {}
  return (
    <Tooltip title="Vote" arrow>
      <IconButton onClick={f}>
        <ArrowCircleUpIcon fontSize="large" />
        <ArrowCircleDownIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}
export function PRepl(item: post) {
  const pood = item.getPood();
  const food = item.getPood();
  return (
    <Tooltip title="Reply" arrow>
      <Link to="/post/repl" state={{ pood, food }}>
        <IconButton>
          <ReplyIcon fontSize="large" />
        </IconButton>
      </Link>
    </Tooltip>
  );
}
export function PMark(item: post) {
  function f() {}
  return (
    <Tooltip title="Mark" arrow>
      <IconButton onClick={f}>
        <BookmarkAddIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}
export function PShar(item: post) {
  function f() {}
  return (
    <Tooltip title="Share" arrow>
      <IconButton onClick={f}>
        <ShareIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}
export function PJoin(item: post) {
  function f() {}
  return (
    <Tooltip title="Join" arrow>
      <IconButton onClick={f}>
        <MenuIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}
export function CJoin(item: comu) {
  function f() {}
  return (
    <Tooltip title="Join" arrow>
      <IconButton onClick={f}>
        <MenuIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}
export function PUser(item: post) {
  const data = item.toObject();
  const date = new Date(data.reti * 1000);
  const time = date.toLocaleString();

  const loc = data.uood;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Tooltip title="User Page Todo" arrow>
        {/* <IconButton sx={{ border: 0 }} onClick={f}>
        <SearchRoundedIcon fontSize="large" />
      </IconButton> */}
        <Link to="/chat/hist" state={loc}>
          <IconButton>
            <Avatar
              alt={data.uame}
              src={data.ucon}
              variant="rounded"
              sx={{ width: 30, height: 30 }}
            />
          </IconButton>
        </Link>
      </Tooltip>
      <Typography variant="caption">{data.uame}</Typography>
      <Typography variant="caption">{time}</Typography>
    </Box>
  );
}
export function RUser(item: repl) {
  const data = item.toObject();
  const date = new Date(data.reti * 1000);
  const time = date.toLocaleString();

  const loc = data.uood;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Tooltip title="User Page Todo" arrow>
        {/* <IconButton sx={{ border: 0 }} onClick={f}>
        <SearchRoundedIcon fontSize="large" />
      </IconButton> */}
        <Link to="/chat/hist" state={loc}>
          <IconButton>
            <Avatar
              alt={data.uame}
              src={data.ucon}
              variant="rounded"
              sx={{ width: 30, height: 30 }}
            />
          </IconButton>
        </Link>
      </Tooltip>
      <Typography variant="caption">{data.uame}</Typography>
      <Typography variant="caption">{time}</Typography>
    </Box>
  );
}
export function CComu(item: comu) {
  const data = item.toObject();
  function f() {}
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Tooltip title="User Page Todo" arrow>
        {/* <IconButton  onClick={f}>
        <SearchRoundedIcon fontSize="large" />
      </IconButton> */}
        <Avatar
          alt={data.name}
          src={data.icon}
          variant="rounded"
          sx={{ width: 30, height: 30 }}
        />
      </Tooltip>
      <Typography variant="caption">{data.name}</Typography>
    </Box>
  );
}
export function PComu(item: post) {
  const data = item.toObject();
  function f() {}
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Tooltip title="User Page Todo" arrow>
        {/* <IconButton  onClick={f}>
        <SearchRoundedIcon fontSize="large" />
      </IconButton> */}
        <Avatar
          alt={data.came}
          src={data.ccon}
          variant="rounded"
          sx={{ width: 30, height: 30 }}
        />
      </Tooltip>
      <Typography variant="caption">{data.came}</Typography>
    </Box>
  );
}
export function PUBar(item: post) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      {PUser(item)}
    </Box>
  );
}
export function RUBar(item: repl) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      {RUser(item)}
    </Box>
  );
}
export function PCBar(item: post) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      {PComu(item)}
      {PJoin(item)}
    </Box>
  );
}
export function CCBar(item: comu) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      {CComu(item)}
      {CJoin(item)}
    </Box>
  );
}
export function PXBar(item: post) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      {PVote(item)}
      {PRepl(item)}
      {PMark(item)}
      {PShar(item)}
    </Box>
  );
}

export function PostItem(item: post, func: NavigateFunction) {
  const data = item.toObject();
  // const fly = useNavigate();

  const loc = { pood: data.pood };
  function f() {
    func("/post/data", { state: loc });
  }

  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <SyledCard
        variant="outlined"
        tabIndex={0}
        // onFocus={() => handleFocus(1)}
        // onBlur={handleBlur}
        // className={focusedCardIndex === 1 ? "Mui-focused" : ""}
      >
        <CardActionArea onClick={f}>
          <CardMedia
            component="img"
            alt="green iguana"
            // image={"https://picsum.photos/800/450?random=1"}
            image={data.cove}
            aspect-ratio="16 / 9"
            sx={{
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          />
        </CardActionArea>
        <SyledCardContent>
          {/* <Typography gutterBottom variant="caption" component="div">
            {data.name}
          </Typography> */}
          {PCBar(item)}
          <CardActionArea onClick={f}>
            {/* <CardActionArea> */}
            {/* {Content(data.word)} */}
            <Typography gutterBottom variant="h6" component="div">
              {data.name}
            </Typography>
            <StyledTypography
              variant="body2"
              color="text.secondary"
              gutterBottom
            >
              {data.word}
            </StyledTypography>
          </CardActionArea>
          {PUBar(item)}
          {PXBar(item)}
        </SyledCardContent>
      </SyledCard>
    </Grid>
  );
}

export function ComuItem(item: comu, func: NavigateFunction) {
  const data = item.toObject();
  // const fly = useNavigate();

  const loc = data.cood;
  function f() {
    func("/comu/data", { state: loc });
  }

  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <SyledCard
        variant="outlined"
        tabIndex={0}
        // onFocus={() => handleFocus(1)}
        // onBlur={handleBlur}
        // className={focusedCardIndex === 1 ? "Mui-focused" : ""}
      >
        <CardMedia
          component="img"
          // image={"https://picsum.photos/800/450?random=1"}
          image={data.icon}
          aspect-ratio="16 / 9"
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        />
        <SyledCardContent>
          {/* <Typography gutterBottom variant="caption" component="div">
            {data.name}
          </Typography> */}
          {CCBar(item)}
          <CardActionArea onClick={f}>
            {/* <CardActionArea> */}
            {/* {Content(data.rule)} */}
            <Typography gutterBottom variant="h6" component="div">
              {data.name}
            </Typography>
            <StyledTypography
              variant="body2"
              color="text.secondary"
              gutterBottom
            >
              {data.word}
            </StyledTypography>
          </CardActionArea>
        </SyledCardContent>
      </SyledCard>
    </Grid>
  );
}

export function ReplItem(item: repl) {
  const data = item.toObject();

  return (
    <Grid size={{ xs: 12, md: 10 }} offset={{ md: 1 }}>
      <SyledCard
        variant="outlined"
        tabIndex={0}
        // onFocus={() => handleFocus(1)}
        // onBlur={handleBlur}
        // className={focusedCardIndex === 1 ? "Mui-focused" : ""}
      >
        {/* <CardMedia
          component="img"
          // alt="green iguana"
          image={"logo192.png"}
          // image={"https://picsum.photos/800/450?random=1"}
          aspect-ratio="16 / 9"
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        /> */}
        <SyledCardContent>
          {/* <Typography gutterBottom variant="caption" component="div">
            {data.name}
          </Typography> */}

          {/* <Typography variant="body2" color="text.secondary" gutterBottom>
            {data.word}
          </Typography> */}
          {Content(data.word)}
          {RUBar(item)}
          {/* {XBar(item)} */}
        </SyledCardContent>
      </SyledCard>
    </Grid>
  );
}

export function ComuDetail(item: comu, func: NavigateFunction) {
  const data = item.toObject();
  // const fly = useNavigate();

  const loc = data.cood;
  function f() {
    func("/post/data", { state: loc });
  }

  return (
    <Grid size={{ xs: 12, md: 12 }}>
      <SyledCard
        variant="outlined"
        tabIndex={0}
        // onFocus={() => handleFocus(1)}
        // onBlur={handleBlur}
        // className={focusedCardIndex === 1 ? "Mui-focused" : ""}
      >
        <CardMedia
          component="img"
          // image={"https://picsum.photos/800/450?random=1"}
          image={data.icon}
          aspect-ratio="16 / 9"
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        />
        {Content(data.rule)}
        <SyledCardContent>
          {/* <Typography gutterBottom variant="caption" component="div">
            {data.name}
          </Typography> */}
          {CCBar(item)}
          <CardActionArea onClick={f}>
            {/* <CardActionArea> */}
            {/* {Content(data.rule)} */}
            <Typography gutterBottom variant="h6" component="div">
              {data.name}
            </Typography>
            <StyledTypography
              variant="body2"
              color="text.secondary"
              gutterBottom
            >
              {data.word}
            </StyledTypography>
          </CardActionArea>
          {/* {UBar(item)}
          {XBar(item)} */}
        </SyledCardContent>
      </SyledCard>
    </Grid>
  );
}
export function PostRepl(item: post) {
  const data = item.toObject();
  return (
    <Grid size={{ xs: 12, md: 10 }} offset={{ md: 1 }}>
      <SyledCard
        variant="outlined"
        tabIndex={0}
        // onFocus={() => handleFocus(1)}
        // onBlur={handleBlur}
        // className={focusedCardIndex === 1 ? "Mui-focused" : ""}
      >
        <CardMedia
          component="img"
          alt="green iguana"
          image={data.cove}
          aspect-ratio="16 / 9"
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        />
        <SyledCardContent>
          {/* <Typography gutterBottom variant="caption" component="div">
            {data.name}
          </Typography> */}
          {PCBar(item)}
          <Typography gutterBottom variant="h6" component="div">
            {data.name}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary" gutterBottom>
            {data.word}
          </Typography> */}
          {Content(data.data)}
          {/* {Content("test jaja")} */}
          {/* {Content(dada)} */}
          {PUBar(item)}
          {PXBar(item)}
        </SyledCardContent>
      </SyledCard>
    </Grid>
  );
}
