// @ts-nocheck
/* eslint-disable */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

goog.exportSymbol("proto.code.chat", null, global);
goog.exportSymbol("proto.code.coma", null, global);
goog.exportSymbol("proto.code.comu", null, global);
goog.exportSymbol("proto.code.mark", null, global);
goog.exportSymbol("proto.code.post", null, global);
goog.exportSymbol("proto.code.repl", null, global);
goog.exportSymbol("proto.code.reqs", null, global);
goog.exportSymbol("proto.code.resp", null, global);
goog.exportSymbol("proto.code.subc", null, global);
goog.exportSymbol("proto.code.subu", null, global);
goog.exportSymbol("proto.code.user", null, global);
goog.exportSymbol("proto.code.vote", null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.post = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.post, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.post.displayName = "proto.code.post";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.post.prototype.toObject = function (opt_includeInstance) {
    return proto.code.post.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.post} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.post.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        pood: jspb.Message.getFieldWithDefault(msg, 10, ""),
        cood: jspb.Message.getFieldWithDefault(msg, 11, ""),
        came: jspb.Message.getFieldWithDefault(msg, 12, ""),
        ccon: jspb.Message.getFieldWithDefault(msg, 13, ""),
        uood: jspb.Message.getFieldWithDefault(msg, 14, ""),
        uame: jspb.Message.getFieldWithDefault(msg, 15, ""),
        ucon: jspb.Message.getFieldWithDefault(msg, 16, ""),
        name: jspb.Message.getFieldWithDefault(msg, 17, ""),
        word: jspb.Message.getFieldWithDefault(msg, 18, ""),
        data: jspb.Message.getFieldWithDefault(msg, 19, ""),
        cove: jspb.Message.getFieldWithDefault(msg, 20, ""),
        resc: jspb.Message.getFieldWithDefault(msg, 21, ""),
        rety: jspb.Message.getFieldWithDefault(msg, 22, 0),
        reps: jspb.Message.getFieldWithDefault(msg, 23, 0),
        votu: jspb.Message.getFieldWithDefault(msg, 24, 0),
        votd: jspb.Message.getFieldWithDefault(msg, 25, 0),
        shar: jspb.Message.getFieldWithDefault(msg, 26, 0),
        mark: jspb.Message.getFieldWithDefault(msg, 27, 0),
        stas: jspb.Message.getFieldWithDefault(msg, 28, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 29, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 30, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.post}
 */
proto.code.post.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.post();
  return proto.code.post.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.post} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.post}
 */
proto.code.post.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setPood(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setCood(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setCame(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setCcon(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setUame(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setUcon(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 18:
        var value = /** @type {string} */ (reader.readString());
        msg.setWord(value);
        break;
      case 19:
        var value = /** @type {string} */ (reader.readString());
        msg.setData(value);
        break;
      case 20:
        var value = /** @type {string} */ (reader.readString());
        msg.setCove(value);
        break;
      case 21:
        var value = /** @type {string} */ (reader.readString());
        msg.setResc(value);
        break;
      case 22:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setRety(value);
        break;
      case 23:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReps(value);
        break;
      case 24:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setVotu(value);
        break;
      case 25:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setVotd(value);
        break;
      case 26:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setShar(value);
        break;
      case 27:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setMark(value);
        break;
      case 28:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 29:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 30:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.post.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.post.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.post} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.post.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPood();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getCood();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
  f = message.getCame();
  if (f.length > 0) {
    writer.writeString(12, f);
  }
  f = message.getCcon();
  if (f.length > 0) {
    writer.writeString(13, f);
  }
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(14, f);
  }
  f = message.getUame();
  if (f.length > 0) {
    writer.writeString(15, f);
  }
  f = message.getUcon();
  if (f.length > 0) {
    writer.writeString(16, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(17, f);
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(18, f);
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(19, f);
  }
  f = message.getCove();
  if (f.length > 0) {
    writer.writeString(20, f);
  }
  f = message.getResc();
  if (f.length > 0) {
    writer.writeString(21, f);
  }
  f = message.getRety();
  if (f !== 0) {
    writer.writeUint32(22, f);
  }
  f = message.getReps();
  if (f !== 0) {
    writer.writeUint32(23, f);
  }
  f = message.getVotu();
  if (f !== 0) {
    writer.writeUint32(24, f);
  }
  f = message.getVotd();
  if (f !== 0) {
    writer.writeUint32(25, f);
  }
  f = message.getShar();
  if (f !== 0) {
    writer.writeUint32(26, f);
  }
  f = message.getMark();
  if (f !== 0) {
    writer.writeUint32(27, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(28, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(29, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(30, f);
  }
};

/**
 * optional string pood = 10;
 * @return {string}
 */
proto.code.post.prototype.getPood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};

/** @param {string} value */
proto.code.post.prototype.setPood = function (value) {
  jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional string cood = 11;
 * @return {string}
 */
proto.code.post.prototype.getCood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};

/** @param {string} value */
proto.code.post.prototype.setCood = function (value) {
  jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional string came = 12;
 * @return {string}
 */
proto.code.post.prototype.getCame = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};

/** @param {string} value */
proto.code.post.prototype.setCame = function (value) {
  jspb.Message.setProto3StringField(this, 12, value);
};

/**
 * optional string ccon = 13;
 * @return {string}
 */
proto.code.post.prototype.getCcon = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};

/** @param {string} value */
proto.code.post.prototype.setCcon = function (value) {
  jspb.Message.setProto3StringField(this, 13, value);
};

/**
 * optional string uood = 14;
 * @return {string}
 */
proto.code.post.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};

/** @param {string} value */
proto.code.post.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 14, value);
};

/**
 * optional string uame = 15;
 * @return {string}
 */
proto.code.post.prototype.getUame = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};

/** @param {string} value */
proto.code.post.prototype.setUame = function (value) {
  jspb.Message.setProto3StringField(this, 15, value);
};

/**
 * optional string ucon = 16;
 * @return {string}
 */
proto.code.post.prototype.getUcon = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};

/** @param {string} value */
proto.code.post.prototype.setUcon = function (value) {
  jspb.Message.setProto3StringField(this, 16, value);
};

/**
 * optional string name = 17;
 * @return {string}
 */
proto.code.post.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};

/** @param {string} value */
proto.code.post.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 17, value);
};

/**
 * optional string word = 18;
 * @return {string}
 */
proto.code.post.prototype.getWord = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};

/** @param {string} value */
proto.code.post.prototype.setWord = function (value) {
  jspb.Message.setProto3StringField(this, 18, value);
};

/**
 * optional string data = 19;
 * @return {string}
 */
proto.code.post.prototype.getData = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};

/** @param {string} value */
proto.code.post.prototype.setData = function (value) {
  jspb.Message.setProto3StringField(this, 19, value);
};

/**
 * optional string cove = 20;
 * @return {string}
 */
proto.code.post.prototype.getCove = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};

/** @param {string} value */
proto.code.post.prototype.setCove = function (value) {
  jspb.Message.setProto3StringField(this, 20, value);
};

/**
 * optional string resc = 21;
 * @return {string}
 */
proto.code.post.prototype.getResc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};

/** @param {string} value */
proto.code.post.prototype.setResc = function (value) {
  jspb.Message.setProto3StringField(this, 21, value);
};

/**
 * optional uint32 rety = 22;
 * @return {number}
 */
proto.code.post.prototype.getRety = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};

/** @param {number} value */
proto.code.post.prototype.setRety = function (value) {
  jspb.Message.setProto3IntField(this, 22, value);
};

/**
 * optional uint32 reps = 23;
 * @return {number}
 */
proto.code.post.prototype.getReps = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};

/** @param {number} value */
proto.code.post.prototype.setReps = function (value) {
  jspb.Message.setProto3IntField(this, 23, value);
};

/**
 * optional uint32 votu = 24;
 * @return {number}
 */
proto.code.post.prototype.getVotu = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};

/** @param {number} value */
proto.code.post.prototype.setVotu = function (value) {
  jspb.Message.setProto3IntField(this, 24, value);
};

/**
 * optional uint32 votd = 25;
 * @return {number}
 */
proto.code.post.prototype.getVotd = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};

/** @param {number} value */
proto.code.post.prototype.setVotd = function (value) {
  jspb.Message.setProto3IntField(this, 25, value);
};

/**
 * optional uint32 shar = 26;
 * @return {number}
 */
proto.code.post.prototype.getShar = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};

/** @param {number} value */
proto.code.post.prototype.setShar = function (value) {
  jspb.Message.setProto3IntField(this, 26, value);
};

/**
 * optional uint32 mark = 27;
 * @return {number}
 */
proto.code.post.prototype.getMark = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};

/** @param {number} value */
proto.code.post.prototype.setMark = function (value) {
  jspb.Message.setProto3IntField(this, 27, value);
};

/**
 * optional uint32 stas = 28;
 * @return {number}
 */
proto.code.post.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};

/** @param {number} value */
proto.code.post.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 28, value);
};

/**
 * optional uint32 inti = 29;
 * @return {number}
 */
proto.code.post.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};

/** @param {number} value */
proto.code.post.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 29, value);
};

/**
 * optional uint32 reti = 30;
 * @return {number}
 */
proto.code.post.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};

/** @param {number} value */
proto.code.post.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 30, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.repl = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.repl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.repl.displayName = "proto.code.repl";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.repl.prototype.toObject = function (opt_includeInstance) {
    return proto.code.repl.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.repl} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.repl.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        rood: jspb.Message.getFieldWithDefault(msg, 10, ""),
        food: jspb.Message.getFieldWithDefault(msg, 11, ""),
        pood: jspb.Message.getFieldWithDefault(msg, 12, ""),
        uood: jspb.Message.getFieldWithDefault(msg, 13, ""),
        uame: jspb.Message.getFieldWithDefault(msg, 14, ""),
        ucon: jspb.Message.getFieldWithDefault(msg, 15, ""),
        word: jspb.Message.getFieldWithDefault(msg, 16, ""),
        resc: jspb.Message.getFieldWithDefault(msg, 17, ""),
        rety: jspb.Message.getFieldWithDefault(msg, 18, 0),
        votu: jspb.Message.getFieldWithDefault(msg, 19, 0),
        votd: jspb.Message.getFieldWithDefault(msg, 20, 0),
        stas: jspb.Message.getFieldWithDefault(msg, 21, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 22, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 23, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.repl}
 */
proto.code.repl.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.repl();
  return proto.code.repl.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.repl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.repl}
 */
proto.code.repl.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setRood(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setFood(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setPood(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setUame(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setUcon(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setWord(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setResc(value);
        break;
      case 18:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setRety(value);
        break;
      case 19:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setVotu(value);
        break;
      case 20:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setVotd(value);
        break;
      case 21:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 22:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 23:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.repl.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.repl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.repl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.repl.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRood();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getFood();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
  f = message.getPood();
  if (f.length > 0) {
    writer.writeString(12, f);
  }
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(13, f);
  }
  f = message.getUame();
  if (f.length > 0) {
    writer.writeString(14, f);
  }
  f = message.getUcon();
  if (f.length > 0) {
    writer.writeString(15, f);
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(16, f);
  }
  f = message.getResc();
  if (f.length > 0) {
    writer.writeString(17, f);
  }
  f = message.getRety();
  if (f !== 0) {
    writer.writeUint32(18, f);
  }
  f = message.getVotu();
  if (f !== 0) {
    writer.writeUint32(19, f);
  }
  f = message.getVotd();
  if (f !== 0) {
    writer.writeUint32(20, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(21, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(22, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(23, f);
  }
};

/**
 * optional string rood = 10;
 * @return {string}
 */
proto.code.repl.prototype.getRood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};

/** @param {string} value */
proto.code.repl.prototype.setRood = function (value) {
  jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional string food = 11;
 * @return {string}
 */
proto.code.repl.prototype.getFood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};

/** @param {string} value */
proto.code.repl.prototype.setFood = function (value) {
  jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional string pood = 12;
 * @return {string}
 */
proto.code.repl.prototype.getPood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};

/** @param {string} value */
proto.code.repl.prototype.setPood = function (value) {
  jspb.Message.setProto3StringField(this, 12, value);
};

/**
 * optional string uood = 13;
 * @return {string}
 */
proto.code.repl.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};

/** @param {string} value */
proto.code.repl.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 13, value);
};

/**
 * optional string uame = 14;
 * @return {string}
 */
proto.code.repl.prototype.getUame = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};

/** @param {string} value */
proto.code.repl.prototype.setUame = function (value) {
  jspb.Message.setProto3StringField(this, 14, value);
};

/**
 * optional string ucon = 15;
 * @return {string}
 */
proto.code.repl.prototype.getUcon = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};

/** @param {string} value */
proto.code.repl.prototype.setUcon = function (value) {
  jspb.Message.setProto3StringField(this, 15, value);
};

/**
 * optional string word = 16;
 * @return {string}
 */
proto.code.repl.prototype.getWord = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};

/** @param {string} value */
proto.code.repl.prototype.setWord = function (value) {
  jspb.Message.setProto3StringField(this, 16, value);
};

/**
 * optional string resc = 17;
 * @return {string}
 */
proto.code.repl.prototype.getResc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};

/** @param {string} value */
proto.code.repl.prototype.setResc = function (value) {
  jspb.Message.setProto3StringField(this, 17, value);
};

/**
 * optional uint32 rety = 18;
 * @return {number}
 */
proto.code.repl.prototype.getRety = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};

/** @param {number} value */
proto.code.repl.prototype.setRety = function (value) {
  jspb.Message.setProto3IntField(this, 18, value);
};

/**
 * optional uint32 votu = 19;
 * @return {number}
 */
proto.code.repl.prototype.getVotu = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};

/** @param {number} value */
proto.code.repl.prototype.setVotu = function (value) {
  jspb.Message.setProto3IntField(this, 19, value);
};

/**
 * optional uint32 votd = 20;
 * @return {number}
 */
proto.code.repl.prototype.getVotd = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};

/** @param {number} value */
proto.code.repl.prototype.setVotd = function (value) {
  jspb.Message.setProto3IntField(this, 20, value);
};

/**
 * optional uint32 stas = 21;
 * @return {number}
 */
proto.code.repl.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};

/** @param {number} value */
proto.code.repl.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 21, value);
};

/**
 * optional uint32 inti = 22;
 * @return {number}
 */
proto.code.repl.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};

/** @param {number} value */
proto.code.repl.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 22, value);
};

/**
 * optional uint32 reti = 23;
 * @return {number}
 */
proto.code.repl.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};

/** @param {number} value */
proto.code.repl.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 23, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.user = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.user, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.user.displayName = "proto.code.user";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.user.prototype.toObject = function (opt_includeInstance) {
    return proto.code.user.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.user} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.user.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        name: jspb.Message.getFieldWithDefault(msg, 2, ""),
        mail: jspb.Message.getFieldWithDefault(msg, 3, ""),
        pass: jspb.Message.getFieldWithDefault(msg, 4, ""),
        icon: jspb.Message.getFieldWithDefault(msg, 5, ""),
        word: jspb.Message.getFieldWithDefault(msg, 6, ""),
        stas: jspb.Message.getFieldWithDefault(msg, 7, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 8, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 9, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.user}
 */
proto.code.user.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.user();
  return proto.code.user.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.user} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.user}
 */
proto.code.user.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMail(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setPass(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setIcon(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setWord(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.user.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.user.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.user} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.user.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMail();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getPass();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(7, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(8, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(9, f);
  }
};

/**
 * optional string uood = 1;
 * @return {string}
 */
proto.code.user.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.user.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.code.user.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.user.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string mail = 3;
 * @return {string}
 */
proto.code.user.prototype.getMail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.code.user.prototype.setMail = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string pass = 4;
 * @return {string}
 */
proto.code.user.prototype.getPass = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/** @param {string} value */
proto.code.user.prototype.setPass = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string icon = 5;
 * @return {string}
 */
proto.code.user.prototype.getIcon = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/** @param {string} value */
proto.code.user.prototype.setIcon = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string word = 6;
 * @return {string}
 */
proto.code.user.prototype.getWord = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/** @param {string} value */
proto.code.user.prototype.setWord = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional uint32 stas = 7;
 * @return {number}
 */
proto.code.user.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/** @param {number} value */
proto.code.user.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional uint32 inti = 8;
 * @return {number}
 */
proto.code.user.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/** @param {number} value */
proto.code.user.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional uint32 reti = 9;
 * @return {number}
 */
proto.code.user.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/** @param {number} value */
proto.code.user.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.chat = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.chat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.chat.displayName = "proto.code.chat";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.chat.prototype.toObject = function (opt_includeInstance) {
    return proto.code.chat.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.chat} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.chat.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        mood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        word: jspb.Message.getFieldWithDefault(msg, 2, ""),
        food: jspb.Message.getFieldWithDefault(msg, 3, ""),
        uame: jspb.Message.getFieldWithDefault(msg, 4, ""),
        ucon: jspb.Message.getFieldWithDefault(msg, 5, ""),
        tood: jspb.Message.getFieldWithDefault(msg, 6, ""),
        stas: jspb.Message.getFieldWithDefault(msg, 7, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 8, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 9, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.chat}
 */
proto.code.chat.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.chat();
  return proto.code.chat.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.chat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.chat}
 */
proto.code.chat.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setWord(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setFood(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setUame(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setUcon(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setTood(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.chat.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.chat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.chat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.chat.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getFood();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getUame();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getUcon();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getTood();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(7, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(8, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(9, f);
  }
};

/**
 * optional string mood = 1;
 * @return {string}
 */
proto.code.chat.prototype.getMood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.chat.prototype.setMood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string word = 2;
 * @return {string}
 */
proto.code.chat.prototype.getWord = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.chat.prototype.setWord = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string food = 3;
 * @return {string}
 */
proto.code.chat.prototype.getFood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.code.chat.prototype.setFood = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string uame = 4;
 * @return {string}
 */
proto.code.chat.prototype.getUame = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/** @param {string} value */
proto.code.chat.prototype.setUame = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string ucon = 5;
 * @return {string}
 */
proto.code.chat.prototype.getUcon = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/** @param {string} value */
proto.code.chat.prototype.setUcon = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string tood = 6;
 * @return {string}
 */
proto.code.chat.prototype.getTood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/** @param {string} value */
proto.code.chat.prototype.setTood = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional uint32 stas = 7;
 * @return {number}
 */
proto.code.chat.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/** @param {number} value */
proto.code.chat.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional uint32 inti = 8;
 * @return {number}
 */
proto.code.chat.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/** @param {number} value */
proto.code.chat.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional uint32 reti = 9;
 * @return {number}
 */
proto.code.chat.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/** @param {number} value */
proto.code.chat.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.comu = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.comu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.comu.displayName = "proto.code.comu";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.comu.prototype.toObject = function (opt_includeInstance) {
    return proto.code.comu.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.comu} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.comu.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        cood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        name: jspb.Message.getFieldWithDefault(msg, 2, ""),
        word: jspb.Message.getFieldWithDefault(msg, 3, ""),
        icon: jspb.Message.getFieldWithDefault(msg, 4, ""),
        rule: jspb.Message.getFieldWithDefault(msg, 5, ""),
        popu: jspb.Message.getFieldWithDefault(msg, 6, 0),
        stas: jspb.Message.getFieldWithDefault(msg, 7, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 8, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 9, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.comu}
 */
proto.code.comu.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.comu();
  return proto.code.comu.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.comu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.comu}
 */
proto.code.comu.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setWord(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setIcon(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setRule(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPopu(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.comu.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.comu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.comu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.comu.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getRule();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getPopu();
  if (f !== 0) {
    writer.writeUint32(6, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(7, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(8, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(9, f);
  }
};

/**
 * optional string cood = 1;
 * @return {string}
 */
proto.code.comu.prototype.getCood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.comu.prototype.setCood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.code.comu.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.comu.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string word = 3;
 * @return {string}
 */
proto.code.comu.prototype.getWord = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.code.comu.prototype.setWord = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string icon = 4;
 * @return {string}
 */
proto.code.comu.prototype.getIcon = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/** @param {string} value */
proto.code.comu.prototype.setIcon = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string rule = 5;
 * @return {string}
 */
proto.code.comu.prototype.getRule = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/** @param {string} value */
proto.code.comu.prototype.setRule = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional uint32 popu = 6;
 * @return {number}
 */
proto.code.comu.prototype.getPopu = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/** @param {number} value */
proto.code.comu.prototype.setPopu = function (value) {
  jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional uint32 stas = 7;
 * @return {number}
 */
proto.code.comu.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/** @param {number} value */
proto.code.comu.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional uint32 inti = 8;
 * @return {number}
 */
proto.code.comu.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/** @param {number} value */
proto.code.comu.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional uint32 reti = 9;
 * @return {number}
 */
proto.code.comu.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/** @param {number} value */
proto.code.comu.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.coma = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.coma, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.coma.displayName = "proto.code.coma";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.coma.prototype.toObject = function (opt_includeInstance) {
    return proto.code.coma.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.coma} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.coma.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        cood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        uood: jspb.Message.getFieldWithDefault(msg, 2, ""),
        type: jspb.Message.getFieldWithDefault(msg, 3, 0),
        stas: jspb.Message.getFieldWithDefault(msg, 4, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 5, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 6, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.coma}
 */
proto.code.coma.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.coma();
  return proto.code.coma.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.coma} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.coma}
 */
proto.code.coma.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setType(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.coma.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.coma.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.coma} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.coma.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(6, f);
  }
};

/**
 * optional string cood = 1;
 * @return {string}
 */
proto.code.coma.prototype.getCood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.coma.prototype.setCood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string uood = 2;
 * @return {string}
 */
proto.code.coma.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.coma.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional uint32 type = 3;
 * @return {number}
 */
proto.code.coma.prototype.getType = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.code.coma.prototype.setType = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 stas = 4;
 * @return {number}
 */
proto.code.coma.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/** @param {number} value */
proto.code.coma.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional uint32 inti = 5;
 * @return {number}
 */
proto.code.coma.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.code.coma.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional uint32 reti = 6;
 * @return {number}
 */
proto.code.coma.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/** @param {number} value */
proto.code.coma.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.subc = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.subc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.subc.displayName = "proto.code.subc";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.subc.prototype.toObject = function (opt_includeInstance) {
    return proto.code.subc.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.subc} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.subc.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        food: jspb.Message.getFieldWithDefault(msg, 2, ""),
        stas: jspb.Message.getFieldWithDefault(msg, 3, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 4, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 5, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.subc}
 */
proto.code.subc.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.subc();
  return proto.code.subc.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.subc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.subc}
 */
proto.code.subc.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setFood(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.subc.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.subc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.subc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.subc.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFood();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
};

/**
 * optional string uood = 1;
 * @return {string}
 */
proto.code.subc.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.subc.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string food = 2;
 * @return {string}
 */
proto.code.subc.prototype.getFood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.subc.prototype.setFood = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional uint32 stas = 3;
 * @return {number}
 */
proto.code.subc.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.code.subc.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 inti = 4;
 * @return {number}
 */
proto.code.subc.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/** @param {number} value */
proto.code.subc.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional uint32 reti = 5;
 * @return {number}
 */
proto.code.subc.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.code.subc.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.subu = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.subu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.subu.displayName = "proto.code.subu";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.subu.prototype.toObject = function (opt_includeInstance) {
    return proto.code.subu.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.subu} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.subu.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        food: jspb.Message.getFieldWithDefault(msg, 2, ""),
        stas: jspb.Message.getFieldWithDefault(msg, 3, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 4, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 5, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.subu}
 */
proto.code.subu.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.subu();
  return proto.code.subu.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.subu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.subu}
 */
proto.code.subu.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setFood(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.subu.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.subu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.subu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.subu.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFood();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
};

/**
 * optional string uood = 1;
 * @return {string}
 */
proto.code.subu.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.subu.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string food = 2;
 * @return {string}
 */
proto.code.subu.prototype.getFood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.subu.prototype.setFood = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional uint32 stas = 3;
 * @return {number}
 */
proto.code.subu.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.code.subu.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 inti = 4;
 * @return {number}
 */
proto.code.subu.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/** @param {number} value */
proto.code.subu.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional uint32 reti = 5;
 * @return {number}
 */
proto.code.subu.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.code.subu.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.mark = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.mark, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.mark.displayName = "proto.code.mark";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.mark.prototype.toObject = function (opt_includeInstance) {
    return proto.code.mark.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.mark} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.mark.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        pood: jspb.Message.getFieldWithDefault(msg, 2, ""),
        stas: jspb.Message.getFieldWithDefault(msg, 3, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 4, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 5, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.mark}
 */
proto.code.mark.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.mark();
  return proto.code.mark.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.mark} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.mark}
 */
proto.code.mark.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setPood(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.mark.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.mark.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.mark} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.mark.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getPood();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
};

/**
 * optional string uood = 1;
 * @return {string}
 */
proto.code.mark.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.mark.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string pood = 2;
 * @return {string}
 */
proto.code.mark.prototype.getPood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.mark.prototype.setPood = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional uint32 stas = 3;
 * @return {number}
 */
proto.code.mark.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.code.mark.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 inti = 4;
 * @return {number}
 */
proto.code.mark.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/** @param {number} value */
proto.code.mark.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional uint32 reti = 5;
 * @return {number}
 */
proto.code.mark.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.code.mark.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.vote = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.vote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.vote.displayName = "proto.code.vote";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.vote.prototype.toObject = function (opt_includeInstance) {
    return proto.code.vote.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.vote} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.vote.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        rood: jspb.Message.getFieldWithDefault(msg, 2, ""),
        type: jspb.Message.getFieldWithDefault(msg, 3, 0),
        stas: jspb.Message.getFieldWithDefault(msg, 4, 0),
        inti: jspb.Message.getFieldWithDefault(msg, 5, 0),
        reti: jspb.Message.getFieldWithDefault(msg, 6, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.vote}
 */
proto.code.vote.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.vote();
  return proto.code.vote.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.vote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.vote}
 */
proto.code.vote.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setRood(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setType(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStas(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInti(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setReti(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.vote.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.vote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.vote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.vote.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getRood();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getStas();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getInti();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
  f = message.getReti();
  if (f !== 0) {
    writer.writeUint32(6, f);
  }
};

/**
 * optional string uood = 1;
 * @return {string}
 */
proto.code.vote.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.vote.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string rood = 2;
 * @return {string}
 */
proto.code.vote.prototype.getRood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.vote.prototype.setRood = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional uint32 type = 3;
 * @return {number}
 */
proto.code.vote.prototype.getType = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.code.vote.prototype.setType = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 stas = 4;
 * @return {number}
 */
proto.code.vote.prototype.getStas = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/** @param {number} value */
proto.code.vote.prototype.setStas = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional uint32 inti = 5;
 * @return {number}
 */
proto.code.vote.prototype.getInti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.code.vote.prototype.setInti = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional uint32 reti = 6;
 * @return {number}
 */
proto.code.vote.prototype.getReti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/** @param {number} value */
proto.code.vote.prototype.setReti = function (value) {
  jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.reqs = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.reqs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.reqs.displayName = "proto.code.reqs";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.reqs.prototype.toObject = function (opt_includeInstance) {
    return proto.code.reqs.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.reqs} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.reqs.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uood: jspb.Message.getFieldWithDefault(msg, 1, ""),
        pood: jspb.Message.getFieldWithDefault(msg, 2, ""),
        rood: jspb.Message.getFieldWithDefault(msg, 3, ""),
        cood: jspb.Message.getFieldWithDefault(msg, 4, ""),
        mood: jspb.Message.getFieldWithDefault(msg, 5, ""),
        food: jspb.Message.getFieldWithDefault(msg, 6, ""),
        name: jspb.Message.getFieldWithDefault(msg, 7, ""),
        type: jspb.Message.getFieldWithDefault(msg, 8, 0),
        time: jspb.Message.getFieldWithDefault(msg, 9, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.reqs}
 */
proto.code.reqs.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.reqs();
  return proto.code.reqs.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.reqs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.reqs}
 */
proto.code.reqs.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUood(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setPood(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setRood(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCood(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setMood(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setFood(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setType(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setTime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.reqs.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.reqs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.reqs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.reqs.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUood();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getPood();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getRood();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getCood();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getMood();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getFood();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(8, f);
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeUint32(9, f);
  }
};

/**
 * optional string uood = 1;
 * @return {string}
 */
proto.code.reqs.prototype.getUood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.reqs.prototype.setUood = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string pood = 2;
 * @return {string}
 */
proto.code.reqs.prototype.getPood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.reqs.prototype.setPood = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string rood = 3;
 * @return {string}
 */
proto.code.reqs.prototype.getRood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.code.reqs.prototype.setRood = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string cood = 4;
 * @return {string}
 */
proto.code.reqs.prototype.getCood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/** @param {string} value */
proto.code.reqs.prototype.setCood = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string mood = 5;
 * @return {string}
 */
proto.code.reqs.prototype.getMood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/** @param {string} value */
proto.code.reqs.prototype.setMood = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string food = 6;
 * @return {string}
 */
proto.code.reqs.prototype.getFood = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/** @param {string} value */
proto.code.reqs.prototype.setFood = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string name = 7;
 * @return {string}
 */
proto.code.reqs.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/** @param {string} value */
proto.code.reqs.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional uint32 type = 8;
 * @return {number}
 */
proto.code.reqs.prototype.getType = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/** @param {number} value */
proto.code.reqs.prototype.setType = function (value) {
  jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional uint32 time = 9;
 * @return {number}
 */
proto.code.reqs.prototype.getTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/** @param {number} value */
proto.code.reqs.prototype.setTime = function (value) {
  jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.code.resp = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.code.resp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.code.resp.displayName = "proto.code.resp";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.code.resp.prototype.toObject = function (opt_includeInstance) {
    return proto.code.resp.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.code.resp} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.code.resp.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, ""),
        word: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.code.resp}
 */
proto.code.resp.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.code.resp();
  return proto.code.resp.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.code.resp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.code.resp}
 */
proto.code.resp.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setWord(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.code.resp.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.code.resp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.code.resp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.code.resp.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string code = 1;
 * @return {string}
 */
proto.code.resp.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.code.resp.prototype.setCode = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string word = 2;
 * @return {string}
 */
proto.code.resp.prototype.getWord = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.code.resp.prototype.setWord = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

goog.object.extend(exports, proto.code);
