import { co } from "./envs";
import { comu, coma, resp, reqs } from "../proto/code_pb";
import { useEffect, useState } from "react";
import * as grpcWeb from "grpc-web";

function HandsErr(err: grpcWeb.RpcError) {
  console.log(err);
  return "";
}

export function SetaComuCood(comu: comu) {
  function Process(err: grpcWeb.RpcError, res: resp) {
    if (err) HandsErr(err);
    else {
      console.log(res);
    }
  }
  co.setaComuCood(comu, {}, Process);
}
export function SetaComuComa(coma: coma) {
  function Process(err: grpcWeb.RpcError, res: resp) {
    if (err) HandsErr(err);
    else {
      console.log(res);
    }
  }
  co.setaComuComa(coma, {}, Process);
}

export function useGetaComuCood(reqs: reqs) {
  let data = new comu();
  const [item, setItem] = useState(data);

  function Process(err: grpcWeb.RpcError, res: comu) {
    if (err) HandsErr(err);
    else {
      console.log(res);
      setItem(res);
    }
  }
  function Comus() {
    co.getaComuCood(reqs, {}, Process);
  }
  useEffect(Comus, []);
  return item;
}

export function useGetsComuName(reqs: reqs) {
  let datas: comu[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    console.log("finally did");
    setEnds(true);
  }
  function Data(res: comu) {
    datas.push(res);
    setItem(datas);
  }
  function Comus() {
    setEnds(false);
    let stream = co.getsComuName(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Comus, []);
  return item;
}

export function useGetsComaCood(reqs: reqs) {
  let datas: coma[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    console.log("finally did");
    setEnds(true);
  }
  function Data(res: coma) {
    datas.push(res);
    setItem(datas);
  }
  function Comus() {
    setEnds(false);
    let stream = co.getsComaCood(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Comus, []);
  return item;
}
