import { us } from "./envs";
import { user, comu, reqs, resp } from "../proto/code_pb";
import { useEffect, useState } from "react";
import * as grpcWeb from "grpc-web";
import { NavigateFunction } from "react-router-dom";
import { State } from "src/auth/auth";
function HandsErr(err: grpcWeb.RpcError) {
  console.log(err);
  return "";
}
export function SetaUserUood(user: user) {
  function Process(err: grpcWeb.RpcError, res: resp) {
    HandsErr(err);
  }
  us.setaUserUood(user, {}, Process);
}
export function useSetaUserUood(user: user) {
  const [ret, set] = useState("");
  if (!user.getMail()) return "nonono";
  function Process(err: grpcWeb.RpcError, res: resp) {
    set(err.message);
  }
  us.setaUserUood(user, {}, Process);
  return ret;
}
export function SignUserUood(
  user: user,
  // item: user,
  // stem: React.Dispatch<React.SetStateAction<user>>,
  sets: React.Dispatch<React.SetStateAction<State | undefined>>
) {
  // let data = user;
  // const [item, setItem] = useState(data);

  function Process(err: grpcWeb.RpcError, res: user) {
    if (err) {
      window.alert("log error:" + err.message);
      // stem(user);
    } else {
      // stem(res);
      const data = res.toObject();
      const stat: State = {
        type: true,
        data: {
          user: data.uood,
          mail: data.mail,
          memo: data.pass,
          icon: data.icon,
          name: data.name,
        },
      };

      sets(stat);
      window.alert("successfuly sign in!");
    }
  }
  us.signUserUood(user, {}, Process);
  // return item;
  return;
}
export function InitUserUood(user: user, fly: NavigateFunction) {
  function Process(err: grpcWeb.RpcError, res: resp) {
    if (err) {
      window.alert("create account error" + err.message);
    } else {
      window.alert("successfuly created, goto sign in");
      fly("/user/sign");
    }
  }
  us.setaUserUood(user, {}, Process);
  return;
}
export function useGetaUserUood(reqs: reqs) {
  let data = new user();
  const [item, setItem] = useState(data);

  function Process(err: grpcWeb.RpcError, res: user) {
    if (err) HandsErr(err);
    else {
      console.log(res);
      setItem(res);
    }
  }
  function Users() {
    us.getaUserUood(reqs, {}, Process);
  }
  useEffect(Users, []);
  return item;
}

export function useGetsUserName(reqs: reqs) {
  let datas: user[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    setEnds(true);
  }
  function Data(res: user) {
    datas.push(res);
    setItem(datas);
  }
  function Users() {
    setEnds(false);
    let stream = us.getsUserName(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Users, []);
  return item;
}
export function useGetsUserCood(reqs: reqs) {
  let datas: user[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    setEnds(true);
  }
  function Data(res: user) {
    datas.push(res);
    setItem(datas);
  }
  function Users() {
    setEnds(false);
    let stream = us.getsUserCood(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Users, []);
  return item;
}
