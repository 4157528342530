import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import TopicIcon from "@mui/icons-material/Topic";
import Groups2Icon from "@mui/icons-material/Groups2";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import FolderSharedIcon from "@mui/icons-material/FolderShared";

export default function Navis() {
  const [value, setValue] = React.useState(0);
  // const [messages, setMessages] = React.useState(() => refreshMessages());
  const fly = useNavigate();
  const ref = React.useRef<HTMLDivElement>(null);
  // React.useEffect(() => {
  //   (ref.current as HTMLDivElement).ownerDocument.body.scrollTop = 0;
  //   setMessages(refreshMessages());
  // }, [value, setMessages]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function fa() {
    fly("/chat/list");
  }
  function fb() {
    fly("/post/feed");
  }
  function fc(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  function fd() {
    fly("/comu/list");
  }
  function fe() {
    fly("/user/data");
  }
  function fg() {
    fly("/post/init");
  }
  function fh() {
    fly("/comu/init");
  }
  const men = (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={fg}>New Post</MenuItem>
      <MenuItem onClick={fh}>New Comunity</MenuItem>
    </Menu>
  );
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      {men}
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          onClick={fa}
          label="Chat"
          icon={<ChatBubbleIcon />}
        />
        <BottomNavigationAction
          onClick={fb}
          label="Post"
          icon={<TopicIcon />}
        />
        <BottomNavigationAction onClick={fc} label="Send" icon={<AddIcon />} />
        <BottomNavigationAction
          onClick={fd}
          label="Comu"
          icon={<Groups2Icon />}
        />
        <BottomNavigationAction
          onClick={fe}
          label="User"
          icon={<FolderSharedIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
