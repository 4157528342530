/**
 * @fileoverview gRPC-Web generated client stub for code
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.6.1
// source: code.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as code_pb from './code_pb'; // proto import: "code.proto"


export class postsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSetaPostPood = new grpcWeb.MethodDescriptor(
    '/code.posts/SetaPostPood',
    grpcWeb.MethodType.UNARY,
    code_pb.post,
    code_pb.resp,
    (request: code_pb.post) => {
      return request.serializeBinary();
    },
    code_pb.resp.deserializeBinary
  );

  setaPostPood(
    request: code_pb.post,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.resp>;

  setaPostPood(
    request: code_pb.post,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void): grpcWeb.ClientReadableStream<code_pb.resp>;

  setaPostPood(
    request: code_pb.post,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.posts/SetaPostPood',
        request,
        metadata || {},
        this.methodDescriptorSetaPostPood,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.posts/SetaPostPood',
    request,
    metadata || {},
    this.methodDescriptorSetaPostPood);
  }

  methodDescriptorSetaReplRood = new grpcWeb.MethodDescriptor(
    '/code.posts/SetaReplRood',
    grpcWeb.MethodType.UNARY,
    code_pb.repl,
    code_pb.resp,
    (request: code_pb.repl) => {
      return request.serializeBinary();
    },
    code_pb.resp.deserializeBinary
  );

  setaReplRood(
    request: code_pb.repl,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.resp>;

  setaReplRood(
    request: code_pb.repl,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void): grpcWeb.ClientReadableStream<code_pb.resp>;

  setaReplRood(
    request: code_pb.repl,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.posts/SetaReplRood',
        request,
        metadata || {},
        this.methodDescriptorSetaReplRood,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.posts/SetaReplRood',
    request,
    metadata || {},
    this.methodDescriptorSetaReplRood);
  }

  methodDescriptorGetaPostPood = new grpcWeb.MethodDescriptor(
    '/code.posts/GetaPostPood',
    grpcWeb.MethodType.UNARY,
    code_pb.reqs,
    code_pb.post,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.post.deserializeBinary
  );

  getaPostPood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.post>;

  getaPostPood(
    request: code_pb.reqs,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.post) => void): grpcWeb.ClientReadableStream<code_pb.post>;

  getaPostPood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.post) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.posts/GetaPostPood',
        request,
        metadata || {},
        this.methodDescriptorGetaPostPood,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.posts/GetaPostPood',
    request,
    metadata || {},
    this.methodDescriptorGetaPostPood);
  }

  methodDescriptorGetsPostCood = new grpcWeb.MethodDescriptor(
    '/code.posts/GetsPostCood',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.post,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.post.deserializeBinary
  );

  getsPostCood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.post> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.posts/GetsPostCood',
      request,
      metadata || {},
      this.methodDescriptorGetsPostCood);
  }

  methodDescriptorGetsPostFeed = new grpcWeb.MethodDescriptor(
    '/code.posts/GetsPostFeed',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.post,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.post.deserializeBinary
  );

  getsPostFeed(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.post> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.posts/GetsPostFeed',
      request,
      metadata || {},
      this.methodDescriptorGetsPostFeed);
  }

  methodDescriptorGetsPostMark = new grpcWeb.MethodDescriptor(
    '/code.posts/GetsPostMark',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.post,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.post.deserializeBinary
  );

  getsPostMark(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.post> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.posts/GetsPostMark',
      request,
      metadata || {},
      this.methodDescriptorGetsPostMark);
  }

  methodDescriptorGetsReplRood = new grpcWeb.MethodDescriptor(
    '/code.posts/GetsReplRood',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.repl,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.repl.deserializeBinary
  );

  getsReplRood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.repl> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.posts/GetsReplRood',
      request,
      metadata || {},
      this.methodDescriptorGetsReplRood);
  }

}

export class comusClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSetaComuCood = new grpcWeb.MethodDescriptor(
    '/code.comus/SetaComuCood',
    grpcWeb.MethodType.UNARY,
    code_pb.comu,
    code_pb.resp,
    (request: code_pb.comu) => {
      return request.serializeBinary();
    },
    code_pb.resp.deserializeBinary
  );

  setaComuCood(
    request: code_pb.comu,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.resp>;

  setaComuCood(
    request: code_pb.comu,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void): grpcWeb.ClientReadableStream<code_pb.resp>;

  setaComuCood(
    request: code_pb.comu,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.comus/SetaComuCood',
        request,
        metadata || {},
        this.methodDescriptorSetaComuCood,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.comus/SetaComuCood',
    request,
    metadata || {},
    this.methodDescriptorSetaComuCood);
  }

  methodDescriptorSetaComuComa = new grpcWeb.MethodDescriptor(
    '/code.comus/SetaComuComa',
    grpcWeb.MethodType.UNARY,
    code_pb.coma,
    code_pb.resp,
    (request: code_pb.coma) => {
      return request.serializeBinary();
    },
    code_pb.resp.deserializeBinary
  );

  setaComuComa(
    request: code_pb.coma,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.resp>;

  setaComuComa(
    request: code_pb.coma,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void): grpcWeb.ClientReadableStream<code_pb.resp>;

  setaComuComa(
    request: code_pb.coma,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.comus/SetaComuComa',
        request,
        metadata || {},
        this.methodDescriptorSetaComuComa,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.comus/SetaComuComa',
    request,
    metadata || {},
    this.methodDescriptorSetaComuComa);
  }

  methodDescriptorGetaComuCood = new grpcWeb.MethodDescriptor(
    '/code.comus/GetaComuCood',
    grpcWeb.MethodType.UNARY,
    code_pb.reqs,
    code_pb.comu,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.comu.deserializeBinary
  );

  getaComuCood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.comu>;

  getaComuCood(
    request: code_pb.reqs,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.comu) => void): grpcWeb.ClientReadableStream<code_pb.comu>;

  getaComuCood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.comu) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.comus/GetaComuCood',
        request,
        metadata || {},
        this.methodDescriptorGetaComuCood,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.comus/GetaComuCood',
    request,
    metadata || {},
    this.methodDescriptorGetaComuCood);
  }

  methodDescriptorGetsComaCood = new grpcWeb.MethodDescriptor(
    '/code.comus/GetsComaCood',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.coma,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.coma.deserializeBinary
  );

  getsComaCood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.coma> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.comus/GetsComaCood',
      request,
      metadata || {},
      this.methodDescriptorGetsComaCood);
  }

  methodDescriptorGetsComuName = new grpcWeb.MethodDescriptor(
    '/code.comus/GetsComuName',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.comu,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.comu.deserializeBinary
  );

  getsComuName(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.comu> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.comus/GetsComuName',
      request,
      metadata || {},
      this.methodDescriptorGetsComuName);
  }

}

export class chatsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSendUserChat = new grpcWeb.MethodDescriptor(
    '/code.chats/SendUserChat',
    grpcWeb.MethodType.UNARY,
    code_pb.chat,
    code_pb.resp,
    (request: code_pb.chat) => {
      return request.serializeBinary();
    },
    code_pb.resp.deserializeBinary
  );

  sendUserChat(
    request: code_pb.chat,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.resp>;

  sendUserChat(
    request: code_pb.chat,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void): grpcWeb.ClientReadableStream<code_pb.resp>;

  sendUserChat(
    request: code_pb.chat,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.chats/SendUserChat',
        request,
        metadata || {},
        this.methodDescriptorSendUserChat,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.chats/SendUserChat',
    request,
    metadata || {},
    this.methodDescriptorSendUserChat);
  }

  methodDescriptorGetsUserChat = new grpcWeb.MethodDescriptor(
    '/code.chats/GetsUserChat',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.chat,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.chat.deserializeBinary
  );

  getsUserChat(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.chat> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.chats/GetsUserChat',
      request,
      metadata || {},
      this.methodDescriptorGetsUserChat);
  }

  methodDescriptorGetsChatUood = new grpcWeb.MethodDescriptor(
    '/code.chats/GetsChatUood',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.chat,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.chat.deserializeBinary
  );

  getsChatUood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.chat> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.chats/GetsChatUood',
      request,
      metadata || {},
      this.methodDescriptorGetsChatUood);
  }

}

export class usersClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSetaUserUood = new grpcWeb.MethodDescriptor(
    '/code.users/SetaUserUood',
    grpcWeb.MethodType.UNARY,
    code_pb.user,
    code_pb.resp,
    (request: code_pb.user) => {
      return request.serializeBinary();
    },
    code_pb.resp.deserializeBinary
  );

  setaUserUood(
    request: code_pb.user,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.resp>;

  setaUserUood(
    request: code_pb.user,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void): grpcWeb.ClientReadableStream<code_pb.resp>;

  setaUserUood(
    request: code_pb.user,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.users/SetaUserUood',
        request,
        metadata || {},
        this.methodDescriptorSetaUserUood,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.users/SetaUserUood',
    request,
    metadata || {},
    this.methodDescriptorSetaUserUood);
  }

  methodDescriptorSignUserUood = new grpcWeb.MethodDescriptor(
    '/code.users/SignUserUood',
    grpcWeb.MethodType.UNARY,
    code_pb.user,
    code_pb.user,
    (request: code_pb.user) => {
      return request.serializeBinary();
    },
    code_pb.user.deserializeBinary
  );

  signUserUood(
    request: code_pb.user,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.user>;

  signUserUood(
    request: code_pb.user,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.user) => void): grpcWeb.ClientReadableStream<code_pb.user>;

  signUserUood(
    request: code_pb.user,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.user) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.users/SignUserUood',
        request,
        metadata || {},
        this.methodDescriptorSignUserUood,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.users/SignUserUood',
    request,
    metadata || {},
    this.methodDescriptorSignUserUood);
  }

  methodDescriptorGetaUserUood = new grpcWeb.MethodDescriptor(
    '/code.users/GetaUserUood',
    grpcWeb.MethodType.UNARY,
    code_pb.reqs,
    code_pb.user,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.user.deserializeBinary
  );

  getaUserUood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.user>;

  getaUserUood(
    request: code_pb.reqs,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.user) => void): grpcWeb.ClientReadableStream<code_pb.user>;

  getaUserUood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.user) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.users/GetaUserUood',
        request,
        metadata || {},
        this.methodDescriptorGetaUserUood,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.users/GetaUserUood',
    request,
    metadata || {},
    this.methodDescriptorGetaUserUood);
  }

  methodDescriptorGetsUserCood = new grpcWeb.MethodDescriptor(
    '/code.users/GetsUserCood',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.user,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.user.deserializeBinary
  );

  getsUserCood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.user> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.users/GetsUserCood',
      request,
      metadata || {},
      this.methodDescriptorGetsUserCood);
  }

  methodDescriptorGetsUserName = new grpcWeb.MethodDescriptor(
    '/code.users/GetsUserName',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.user,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.user.deserializeBinary
  );

  getsUserName(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.user> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.users/GetsUserName',
      request,
      metadata || {},
      this.methodDescriptorGetsUserName);
  }

}

export class relasClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSetaComuSubc = new grpcWeb.MethodDescriptor(
    '/code.relas/SetaComuSubc',
    grpcWeb.MethodType.UNARY,
    code_pb.reqs,
    code_pb.resp,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.resp.deserializeBinary
  );

  setaComuSubc(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.resp>;

  setaComuSubc(
    request: code_pb.reqs,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void): grpcWeb.ClientReadableStream<code_pb.resp>;

  setaComuSubc(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.relas/SetaComuSubc',
        request,
        metadata || {},
        this.methodDescriptorSetaComuSubc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.relas/SetaComuSubc',
    request,
    metadata || {},
    this.methodDescriptorSetaComuSubc);
  }

  methodDescriptorSetaUserSubu = new grpcWeb.MethodDescriptor(
    '/code.relas/SetaUserSubu',
    grpcWeb.MethodType.UNARY,
    code_pb.reqs,
    code_pb.resp,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.resp.deserializeBinary
  );

  setaUserSubu(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null): Promise<code_pb.resp>;

  setaUserSubu(
    request: code_pb.reqs,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void): grpcWeb.ClientReadableStream<code_pb.resp>;

  setaUserSubu(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: code_pb.resp) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/code.relas/SetaUserSubu',
        request,
        metadata || {},
        this.methodDescriptorSetaUserSubu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/code.relas/SetaUserSubu',
    request,
    metadata || {},
    this.methodDescriptorSetaUserSubu);
  }

  methodDescriptorGetsSubcUood = new grpcWeb.MethodDescriptor(
    '/code.relas/GetsSubcUood',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.comu,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.comu.deserializeBinary
  );

  getsSubcUood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.comu> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.relas/GetsSubcUood',
      request,
      metadata || {},
      this.methodDescriptorGetsSubcUood);
  }

  methodDescriptorGetsSubuUood = new grpcWeb.MethodDescriptor(
    '/code.relas/GetsSubuUood',
    grpcWeb.MethodType.SERVER_STREAMING,
    code_pb.reqs,
    code_pb.user,
    (request: code_pb.reqs) => {
      return request.serializeBinary();
    },
    code_pb.user.deserializeBinary
  );

  getsSubuUood(
    request: code_pb.reqs,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<code_pb.user> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/code.relas/GetsSubuUood',
      request,
      metadata || {},
      this.methodDescriptorGetsSubuUood);
  }

}

