import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  FormLabel,
  Avatar,
} from "@mui/material";

import ReactQuill from "react-quill";
import Quill, { Quoll, Content } from "src/quill";
import { comu, post, repl, user, reqs } from "src/proto/code_pb";
import { SetaPostPood, SetaReplRood, useGetaPostPood } from "src/apis/post";
import { SetaComuCood } from "src/apis/comu";
import ContextA from "src/auth/auth";
import { State } from "src/auth/auth";
import { useGetsComuName } from "src/apis/comu";
import {
  Link,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { SetaUserUood } from "src/apis/user";
const pagecomu = (
  <Stack spacing={2} useFlexGap>
    <Typography variant="h1">📦</Typography>
    <Typography variant="h5">You have created a new comunity!</Typography>
    <Typography variant="body1" sx={{ color: "text.secondary" }}>
      Try to explore more intersting things!
    </Typography>
    <Link to={"/comu/list"}>
      <Button
        variant="contained"
        sx={{
          alignSelf: "start",
          width: { xs: "100%", sm: "auto" },
        }}
      >
        Check Comunity List
      </Button>
    </Link>
  </Stack>
);
const pagepost = (
  <Stack spacing={2} useFlexGap>
    <Typography variant="h1">📦</Typography>
    <Typography variant="h5">You have created a new Post!</Typography>
    <Typography variant="body1" sx={{ color: "text.secondary" }}>
      Try to explore more intersting things!
    </Typography>
    <Link to={"/post/feed"}>
      <Button
        variant="contained"
        sx={{
          alignSelf: "start",
          width: { xs: "100%", sm: "auto" },
        }}
      >
        Check Post List
      </Button>
    </Link>
  </Stack>
);
const pageuser = (
  <Stack spacing={2} useFlexGap>
    <Typography variant="h1">📦</Typography>
    <Typography variant="h5">You have updated your profile</Typography>
    <Typography variant="body1" sx={{ color: "text.secondary" }}>
      Try to explore more intersting things!
    </Typography>
    <Link to={"/post/feed"}>
      <Button
        variant="contained"
        sx={{
          alignSelf: "start",
          width: { xs: "100%", sm: "auto" },
        }}
      >
        Check Post List
      </Button>
    </Link>
  </Stack>
);

export function UserData(data: user) {
  const u = data.toObject();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        margin: "auto",
      }}
    >
      <FormControl>
        <FormLabel>User Avatar</FormLabel>
        <Avatar src={u.icon} />
      </FormControl>

      <FormControl>
        <FormLabel>User Name</FormLabel>
        <TextField value={u.name} />
      </FormControl>
      <FormControl>
        <FormLabel>User ID</FormLabel>
        <TextField value={u.uood} />
      </FormControl>
      <FormControl>
        <FormLabel>User Mail</FormLabel>
        <TextField value={u.mail} />
      </FormControl>
      <FormControl>
        <FormLabel>User Word</FormLabel>
        <TextField value={u.word} />
      </FormControl>
    </Box>
  );
}
export function UserForm(data: user) {
  const u = data.toObject();
  const [page, flip] = React.useState(0);
  const [word, sord] = React.useState("");
  const [name, same] = React.useState("");
  const [icon, scon] = React.useState("");
  const intros = Intro("profile");
  const detail = LineU(name, word, icon, same, sord, scon);
  const contxt = React.useContext(ContextA);
  const pagenext = () => {
    switch (page) {
      case 0:
        same(u.name);
        scon(u.icon);
        sord(u.word);
        break;
      case 1:
        data.setIcon(icon);
        data.setWord(word);
        data.setName(name);
        break;

      case 2:
        SetaUserUood(data);
        const ux = data.toObject();
        contxt.SignI(
          {
            user: ux.uood,
            mail: ux.mail,
            memo: ux.pass,
            icon: ux.icon,
            name: ux.name,
          },
          ""
        );
        break;

      default:
        break;
    }
    flip(page + 1);
  };
  const pageback = () => {
    flip(page - 1);
  };
  const pagedata = (page: number) => {
    switch (page) {
      case 0:
        return intros;
      case 1:
        return detail;
      case 2:
        return UserData(data);
      default:
        throw new Error("Unknown step");
    }
  };
  const steps = ["Descption", "Set User Datas", "Review your User Profile"];
  return Check(page, steps, pagenext, pageback, pagedata, pageuser);
}
export function PostForm(pos: post, cos: comu[]) {
  const p = pos.toObject();
  const [page, flip] = React.useState(0);
  const [word, sord] = React.useState("");
  const [data, sata] = React.useState("");
  const [name, same] = React.useState("");
  const [cove, sove] = React.useState("");
  const [coom, soom] = React.useState("");
  const ref: React.LegacyRef<ReactQuill> = React.useRef(null);
  const intros = Intro("post");
  const texter = Quoll(ref, data);
  const detail = LineP(cos, name, coom, cove, same, soom, sove);
  const review = (
    <>
      {detail}
      {Content(data)}
    </>
  );

  const pagenext = () => {
    switch (page) {
      case 0:
        sata(p.data);
        same(p.name);
        sove(p.cove);
        soom(p.cood);
        break;
      case 1:
        break;
      case 2:
        const c = ref.current;
        const t = c?.getEditor().getText();
        const v = c?.value;
        sata(v as string);
        sord(t as string);
        break;
      case 3:
        pos.setCood(coom);
        pos.setWord(word);
        pos.setCove(cove);
        pos.setData(data);
        pos.setName(name);
        SetaPostPood(pos);
        break;

      default:
        break;
    }
    flip(page + 1);
  };
  const pageback = () => {
    flip(page - 1);
  };
  const pagedata = (page: number) => {
    switch (page) {
      case 0:
        return intros;
      case 1:
        return detail;
      case 2:
        return texter;
      case 3:
        return review;
      default:
        throw new Error("Unknown step");
    }
  };
  const steps = [
    "Descption",
    "Set Post Datas",
    "Set Post Contents",
    "Review your post Details",
  ];
  return Check(page, steps, pagenext, pageback, pagedata, pagepost);
}
export function ComuForm(item: comu) {
  const data = item.toObject();
  const [page, flip] = React.useState(0);
  const [word, sord] = React.useState("");
  const [rule, sule] = React.useState("");
  const [name, same] = React.useState("");
  const [icon, scon] = React.useState("");
  const ref: React.LegacyRef<ReactQuill> = React.useRef(null);
  const intros = Intro("comu");
  const texter = Quoll(ref, rule);
  const detail = LineC(name, word, icon, same, sord, scon);
  const review = (
    <>
      {detail}
      {Content(rule)}
    </>
  );
  const pagenext = () => {
    switch (page) {
      case 0:
        sord(data.word);
        same(data.name);
        sule(data.rule);
        scon(data.icon);
        break;
      case 1:
        break;
      case 2:
        const c = ref.current?.value;
        sule(c as string);
        break;
      case 3:
        item.setName(name);
        item.setIcon(icon);
        item.setWord(word);
        item.setRule(rule);
        SetaComuCood(item);
        break;

      default:
        break;
    }
    flip(page + 1);
  };
  const pageback = () => {
    flip(page - 1);
  };
  const pagedata = (page: number) => {
    switch (page) {
      case 0:
        return intros;
      case 1:
        return detail;
      case 2:
        return texter;
      case 3:
        return review;
      default:
        throw new Error("Unknown step");
    }
  };
  const steps = [
    "Descption",
    "Set Comu Datas",
    "Set Comu Rules",
    "Review your comu Details",
  ];
  return Check(page, steps, pagenext, pageback, pagedata, pagecomu);
}
export function ReplForm(r: repl, fly: NavigateFunction) {
  const ref: React.LegacyRef<ReactQuill> = React.useRef(null);
  const val = r.getWord();
  const ins = "Write down your Replys Here.";
  const stops = () => {
    fly(-1);
  };
  const sends = () => {
    const kkk = ref.current?.value;
    r.setWord(kkk as string);
    SetaReplRood(r);
    fly(-1);
  };

  return <Box>{Quill(ref, val, ins, stops, sends)}</Box>;
}

function Intro(v: string) {
  return (
    <Typography variant="h5">Fill in the form to create a new {v}</Typography>
  );
}
function LineU(
  va: string,
  vb: string,
  vc: string,
  fa: React.Dispatch<React.SetStateAction<string>>,
  fb: React.Dispatch<React.SetStateAction<string>>,
  fc: React.Dispatch<React.SetStateAction<string>>
) {
  function FileLoad(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.currentTarget.files;
    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
      let ret = reader.result as string;
      console.log(ret);
      fc(ret);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "60%",
        gap: 2,
        margin: "auto",
      }}
    >
      <FormControl>
        <FormLabel>User Avatar</FormLabel>
        <input type="file" onChange={FileLoad} />
        <Avatar src={vc} sx={{ width: 60, height: 60 }} />
      </FormControl>

      <FormControl>
        <FormLabel>User Name</FormLabel>

        <TextField
          value={va}
          placeholder="User Name"
          onChange={(e) => fa(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>User Word</FormLabel>

        <TextField
          value={vb}
          placeholder="Short Description"
          onChange={(e) => fb(e.target.value)}
        />
      </FormControl>
    </Box>
  );
}
function LineP(
  co: comu[],
  va: string,
  vb: string,
  vc: string,
  fa: React.Dispatch<React.SetStateAction<string>>,
  fb: React.Dispatch<React.SetStateAction<string>>,
  fc: React.Dispatch<React.SetStateAction<string>>
) {
  const handleChange = (event: SelectChangeEvent) => {
    fb(event.target.value as string);
  };
  function FileLoad(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.currentTarget.files;
    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
      let ret = reader.result as string;
      console.log(ret);
      fc(ret);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "60%",
        gap: 2,
        margin: "auto",
      }}
    >
      <FormControl>
        <FormLabel>Post Title</FormLabel>

        <TextField
          value={va}
          placeholder="Mail"
          onChange={(e) => fa(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Comu</FormLabel>

        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          // labelId="demo-simple-select-label"
          // id="demo-simple-select"
          value={vb}
          // label="Age"
          onChange={handleChange}
        >
          {co.map((item, _) => (
            <MenuItem value={item.getCood()}>
              <Avatar
                alt={item.getName()}
                src={item.getIcon()}
                variant="rounded"
                sx={{ width: 30, height: 30 }}
              />
              <Typography variant="h5">{item.getName()}</Typography>
            </MenuItem>
          ))}
          {/* <MenuItem value={"10"}>Ten</MenuItem>
        <MenuItem value={"20"}>Twenty</MenuItem>
        <MenuItem value={"30"}>Thirty</MenuItem> */}
        </Select>

        <FormControl>
          <FormLabel>Post Cover</FormLabel>
          <input type="file" onChange={FileLoad} />
        </FormControl>
        <img src={vc} />
      </FormControl>
    </Box>
  );
}
function LineC(
  va: string,
  vb: string,
  vc: string,
  fa: React.Dispatch<React.SetStateAction<string>>,
  fb: React.Dispatch<React.SetStateAction<string>>,
  fc: React.Dispatch<React.SetStateAction<string>>
) {
  function FileLoad(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.currentTarget.files;
    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
      let ret = reader.result as string;
      console.log(ret);
      fc(ret);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "60%",
        gap: 2,
        margin: "auto",
      }}
    >
      <FormControl>
        <FormLabel>Comu Name</FormLabel>

        <TextField
          value={va}
          placeholder="Comunity Name"
          onChange={(e) => fa(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Comu Description</FormLabel>

        <TextField
          value={vb}
          placeholder="Short Description"
          onChange={(e) => fb(e.target.value)}
        />

        <FormControl>
          <FormLabel>Comu Logo</FormLabel>
          <input type="file" onChange={FileLoad} />
        </FormControl>
        <img src={vc} />
      </FormControl>
    </Box>
  );
}

function Check(
  page: number,
  steps: string[],
  pagenext: () => void,
  pageback: () => void,
  pagedata: (x: number) => JSX.Element,
  pagefeed: JSX.Element
) {
  return (
    <Grid container sx={{ height: { xs: "100%", sm: "100dvh" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: { sm: "space-between", md: "flex-end" },
          alignItems: "center",
          width: "100%",
          //   maxWidth: { sm: "100%", md: 600 },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flexGrow: 1,
          }}
        >
          <Stepper
            id="desktop-stepper"
            activeStep={page}
            sx={{ width: "100%", height: 40 }}
          >
            {steps.map((label) => (
              <Step
                sx={{
                  ":first-child": { pl: 0 },
                  ":last-child": { pr: 0 },
                }}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      {/* {Ka(page)} */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
          // maxWidth: { sm: "100%", md: 600 },
          // maxHeight: "720px",
          gap: { xs: 5, md: "none" },
        }}
      >
        <Stepper
          id="mobile-stepper"
          activeStep={page}
          alternativeLabel
          sx={{ display: { sm: "flex", md: "none" } }}
        >
          {steps.map((label) => (
            <Step
              sx={{
                ":first-child": { pl: 0 },
                ":last-child": { pr: 0 },
                "& .MuiStepConnector-root": { top: { xs: 6, sm: 12 } },
              }}
              key={label}
            >
              <StepLabel
                sx={{
                  ".MuiStepLabel-labelContainer": { maxWidth: "70px" },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {page === steps.length ? (
          pagefeed
        ) : (
          <React.Fragment>
            {pagedata(page)}
            <Box
              sx={[
                {
                  display: "flex",
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  alignItems: "end",
                  flexGrow: 1,
                  gap: 1,
                  pb: { xs: 12, sm: 0 },
                  mt: { xs: 2, sm: 0 },
                  mb: "60px",
                },
                page > 1
                  ? { justifyContent: "space-between" }
                  : { justifyContent: "flex-end" },
              ]}
            >
              {page > 1 && (
                <Button
                  startIcon={<ChevronLeftRoundedIcon />}
                  onClick={pageback}
                  variant="text"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  Previous
                </Button>
              )}
              {page > 1 && (
                <Button
                  startIcon={<ChevronLeftRoundedIcon />}
                  onClick={pageback}
                  variant="outlined"
                  fullWidth
                  sx={{ display: { xs: "flex", sm: "none" } }}
                >
                  Previous
                </Button>
              )}
              <Button
                variant="contained"
                endIcon={<ChevronRightRoundedIcon />}
                onClick={pagenext}
                sx={{ width: { xs: "100%", sm: "fit-content" } }}
              >
                {page === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Grid>
  );
}
