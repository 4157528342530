import { ContinuousColorLegend } from "@mui/x-charts";
import * as p from "../proto/CodeServiceClientPb";
import * as w from "grpc-web";
let aa = new w.CallOptions({ token: "123" });
let vv = { token: "123" };
// let url = "http://127.0.0.1:8080";
let url = "http://34.169.110.204:8080";
export let cs = new p.chatsClient(url, null, null);
export let co = new p.comusClient(url, null, null);
export let po = new p.postsClient(url, null, null);
export let re = new p.relasClient(url, null, null);
export let us = new p.usersClient(url, null, null);
