import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./utils";
import Box from "@mui/material/Box";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "./views.css";

export default function Quill(
  ref: React.LegacyRef<ReactQuill>,
  val: string,
  ins: string,
  stop: () => void,
  send: () => void
) {
  modules.toolbar.handlers.stop = stop;
  modules.toolbar.handlers.send = send;
  return (
    <Box>
      <EditorToolbar />
      <ReactQuill
        ref={ref}
        theme="snow"
        value={val}
        placeholder={ins}
        modules={modules}
        formats={formats}
      />
    </Box>
  );
}

export function Buoll(
  ref: React.LegacyRef<ReactQuill>,
  val: string,
  ins: string,
  stop: () => void,
  send: () => void
) {
  modules.toolbar.handlers.stop = stop;
  modules.toolbar.handlers.send = send;
  return (
    <Box>
      <EditorToolbar />
      <ReactQuill
        ref={ref}
        theme="bubble"
        value={val}
        placeholder={ins}
        modules={modules}
        formats={formats}
      />
    </Box>
  );
}
export function Quoll(r: React.LegacyRef<ReactQuill>, v: string) {
  const f = () => {};
  const g = () => {};
  const e = "Write Your Words Here.";
  return Quill(r, v, e, f, g);
}
export function Content(data: string) {
  return <ReactQuill value={data} readOnly theme={"bubble"} />;
}
