import * as React from "react";
import {
  Account,
  AuthenticationContext,
  SessionContext,
  Session,
} from "@toolpad/core";
import Context from "src/auth";

export default function AccountCustom() {
  const sss = React.useContext(Context);
  const aaa = sss.State;
  console.log(aaa, "whyy");
  return (
    <>
      {aaa?.name}
      {aaa?.user}
    </>
  );
}
