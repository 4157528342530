import {
  createContext,
  Reducer,
  useReducer,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";

export type UserData = {
  user: string;
  name: string;
  mail: string;
  icon: string;
  memo: string;
};
export type State = {
  type: boolean;
  data: UserData;
};
const UserNA: UserData = {
  user: "",
  name: "",
  mail: "",
  icon: "",
  memo: "",
};
const StateX: State = {
  type: false,
  data: UserNA,
};
type AuthProps = {
  children: React.ReactElement;
};
export interface ContextAuth {
  State: State;
  SignI: (props: UserData, link: string) => void;
  SignO: (link: string) => void;
}
const ContextA = createContext<ContextAuth>({
  State: StateX,
  SignI: () => {},
  SignO: () => {},
});

const ReducerA: Reducer<State, State> = (state, action) => {
  switch (action.type) {
    case true:
      localStorage.setItem("user", JSON.stringify(action.data));
      state.type = true;
      state.data = action.data;
      return state;

    case false:
      localStorage.removeItem("user");
      return StateX;

    default:
      return state;
  }
};

export const AuthContextProvider = (props: AuthProps) => {
  const { children } = props;
  const [State, Stater] = useReducer(ReducerA, StateX);
  const fly = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) return;
    // console.log("i am useffttt");
    // if (user) {
    const data: UserData = JSON.parse(user);
    console.log("running");
    Stater({ type: true, data: data });
    // }
  }, []);

  // const SignI = useCallback((props: UserData, link: string) => {
  //   const data = props;
  //   Stater({ type: true, data: data });
  //   console.log("why not run this");
  //   fly(link);
  // }, []);
  const SignI = useCallback(
    (props: UserData, link: string) => {
      const data = props;
      Stater({ type: true, data: data });
      if (link) fly(link);
    },
    [fly]
  );
  const SignO = useCallback(
    (link: string) => {
      Stater(StateX);
      fly(link);
    },
    [fly]
  );

  // context values to be passed down to children
  const context = { State, SignI, SignO };
  // const vv = useMemo(() => {
  //   return { State, SignI, SignO };
  // }, []);
  return <ContextA.Provider value={context}>{children}</ContextA.Provider>;
};
export default ContextA;
