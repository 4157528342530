import { PostList, ReplList, ComuList, CoPoList } from "./lists";
import { post, repl, reqs, comu } from "src/proto/code_pb";
import {
  useGetaPostPood,
  useGetsPostCood,
  useGetsPostFeed,
  useGetsReplRood,
} from "src/apis/post";
import { useContext, useEffect } from "react";
import ContextA from "src/auth/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetaComuCood, useGetsComuName } from "src/apis/comu";
import { ReplForm, PostForm, ComuForm } from "./forms";
export function PostPage() {
  const ctx = useContext(ContextA);
  console.log(ctx.State);
  const req = new reqs();
  const pos = useGetsPostFeed(req);
  // console.log(pos);
  for (let i of pos) console.log(i.toObject());

  const fly = useNavigate();

  return PostList(pos, fly);
}
export function ReplPage() {
  const req = new reqs();
  const loc = useLocation();
  const pod = loc.state.pood as string;
  req.setRood(pod);
  req.setPood(pod);
  const res = useGetsReplRood(req);
  const pos = useGetaPostPood(req);
  const { State } = useContext(ContextA);
  pos.setUcon(State.data.icon);

  return ReplList(pos, res);
}
export function ComuPage() {
  const req = new reqs();
  const com = useGetsComuName(req);

  const fly = useNavigate();

  return ComuList(com, fly);
}
export function ComuData() {
  const req = new reqs();
  const loc = useLocation();
  const cod = loc.state;
  console.log(cod);
  req.setCood(cod);
  const com = useGetaComuCood(req);
  const pos = useGetsPostCood(req);
  console.log(com.toObject());
  console.log(pos);
  const fly = useNavigate();

  return CoPoList(com, pos, fly);
}
export function SendRepl() {
  const { State, SignO } = useContext(ContextA);
  if (!State.type) SignO("/user/sign");
  const loc = useLocation();
  const fly = useNavigate();
  const { pood, food } = loc.state;
  const u = State.data;
  const r = new repl();
  r.setFood(food);
  r.setPood(pood);
  r.setUood(u.user);
  return ReplForm(r, fly);
}
export function InitPost() {
  const { State, SignO } = useContext(ContextA);
  if (!State.type) SignO("/user/sign");

  const p = new post();
  p.setUood(State.data.user);
  const r = new reqs();
  const coms = useGetsComuName(r);

  return PostForm(p, coms);
}
export function RedoPost() {
  const { State, SignO } = useContext(ContextA);
  if (!State.type) SignO("/user/sign");
  const uood = State.data.user;
  const pood = "";
  const cos: comu[] = [];
  const r = new reqs();
  r.setPood(pood);
  const p = useGetaPostPood(r);
  if (p.getUood() != uood) {
    console.log("no asscess");
  }
  return PostForm(p, cos);
}
export function InitComu() {
  const { State, SignO } = useContext(ContextA);
  if (!State.type) SignO("/user/sign");
  const com = new comu();
  return ComuForm(com);
}
export function RedoComu() {
  const { State, SignO } = useContext(ContextA);
  if (!State.type) SignO("/user/sign");
  const r = new reqs();
  r.setCood("4b5bbfbb-164c-41a9-b299-aa74e39b7c72");
  // todo check user admin
  const com = useGetaComuCood(r);
  return ComuForm(com);
}
