import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { user, chat, reqs } from "src/proto/code_pb";

import XX, { Buoll } from "src/quill";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router";
import { Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Content } from "src/quill";
import ContextA from "src/auth/auth";
import { SendUserChat, useGetsUserChat } from "src/apis/chat";
import { UserData } from "src/auth/auth";
import { useGetaUserUood } from "src/apis/user";
const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const TitleTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  textDecoration: "none",
  "&:hover": { cursor: "pointer" },
  "& .arrow": {
    visibility: "hidden",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  "&:hover .arrow": {
    visibility: "visible",
    opacity: 0.7,
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "3px",
    borderRadius: "8px",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    width: 0,
    height: "1px",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.text.primary,
    opacity: 0.3,
    transition: "width 0.3s ease, opacity 0.3s ease",
  },
  "&:hover::before": {
    width: "100%",
  },
}));

function ChatUser(item: chat, user: user.AsObject) {
  const data = item.toObject();
  const date = new Date(data.reti * 1000);
  const time = date.toLocaleString();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <AvatarGroup max={3}>
          <Avatar
            alt={user.name}
            src={user.icon}
            sx={{ width: 24, height: 24 }}
          />
        </AvatarGroup>
        <Typography variant="caption">{user.name}</Typography>
      </Box>
      <Typography variant="caption">{time}</Typography>
    </Box>
  );
}
function ChatItem(item: chat, usex: user, user: user) {
  const prop1 = [0, 8];
  const prop2 = ["row", "row-reverse"];
  const data = item.toObject();
  const ux = usex.toObject();
  const ur = user.toObject();
  const n = data.food === ur.uood ? 1 : 0;
  const u = data.food === ur.uood ? ur : ux;

  return (
    <Grid size={{ xs: 50, md: 12 }} offset={{ md: prop1[n] }}>
      {/* <Grid key={index} size={{ xs: 50, md: 12 }} offset={{ md: 8 }}> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 1,
          height: "100%",
        }}
      >
        {/* <Typography gutterBottom variant="caption" component="div">
          {data.word}
        </Typography> */}
        {Content(data.word)}
        {/* <StyledTypography variant="body2" color="text.secondary" gutterBottom>
          {data.word}
        </StyledTypography> */}
        {ChatUser(item, u)}
        {/* <Author authors={article.authors} /> */}
      </Box>
    </Grid>
  );
}

export function ChatHist(data: chat[], usex: user, user: user) {
  return (
    <div>
      <Typography variant="h2" gutterBottom>
        Chat Hist
      </Typography>
      <Grid container spacing={5} columns={20}>
        {data.map((item, _) => ChatItem(item, usex, user))}
      </Grid>
      {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
        <Pagination
          hidePrevButton
          hideNextButton
          count={10}
          boundaryCount={10}
        />
      </Box> */}
    </div>
  );
}
function Send(chat: chat) {
  const ref: React.LegacyRef<ReactQuill> = React.useRef(null);
  const fly = useNavigate();
  const val = "";
  const ins = "Write down your Replys...";
  const stops = () => {
    fly(-1);
  };
  const sends = () => {
    const kkk = ref.current?.value;
    chat.setWord(kkk as string);
    SendUserChat(chat);
  };

  return (
    <Paper
      sx={{
        // backgroundColor: "transparent",
        position: "fixed !important",
        // position: "fixed",
        // width : 1086,
        border: 1,
        width: "80%",
        margin: "auto",
        // marginTop: "80vh",
        bottom: 10,
        left: 0,
        right: 0,
      }}
    >
      {Buoll(ref, val, ins, stops, sends)}
    </Paper>
  );
}
export default function Latest() {
  // todo: check user login state

  const data: chat[] = [];
  const item1 = new chat();
  const item2 = new chat();
  const item3 = new chat();
  const uood = "my";
  item1.setWord("hi");
  item1.setFood(uood);
  item2.setWord("too");
  item2.setFood("nob");
  item3.setWord("hi , again");
  item3.setFood(uood);
  data.push(item1);
  data.push(item2);
  data.push(item3);
  const cha = new chat();
  cha.setFood("my");
  cha.setTood("to");

  return (
    <>
      <Box
        sx={
          {
            // backgroundColor: "transparent",
            // position: "fixed !important",
            // width: "100%",
            // marginTop: "80vh",
            // bottom: 100,
            // left: 0,
            // right: 0,
          }
        }
      >
        {/* {ChatHist(data, uood)} */}
      </Box>
      {Send(cha)}
    </>
  );
}
export function ChatData() {
  const { State, SignO } = React.useContext(ContextA);
  if (!State.type) SignO("/user/sign");
  const location = useLocation();
  const food = location.state;
  // const sata = State.data;
  // const uood = sata.user;
  const uses = localStorage.getItem("user") || "";
  const sata: UserData = JSON.parse(uses);
  // console.log(food, uood);
  const r = new reqs();
  const c = new chat();
  r.setFood(sata.user);
  r.setUood(food);
  c.setFood(sata.user);
  c.setTood(food);
  const data = useGetsUserChat(r);
  console.log(data);
  const usex = useGetaUserUood(r);
  // r.setUood(uood);
  const usev = new user();
  usev.setUood(sata.user);
  usev.setIcon(sata.icon);
  usev.setName(sata.name);
  return (
    <>
      <Box>{ChatHist(data, usex, usev)}</Box>
      {Send(c)}
    </>
  );
}
