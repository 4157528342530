import type {} from "@mui/material/themeCssVarsAugmentation";
import { ThemeOptions, PaletteMode } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-charts/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-tree-view/themeAugmentation";
import { getDesignTokens } from "./theme";
import { inputsCustomizations } from "./input";
import { dataDisplayCustomizations } from "./datas";
import { navigationCustomizations } from "./navis";
import { surfacesCustomizations } from "./faces";
import { chartsCustomizations } from "./chart";
import { dataGridCustomizations } from "./grids";
import { datePickersCustomizations } from "./dates";
import { treeViewCustomizations } from "./trees";
import { feedbackCustomizations } from "./feeds";

export default function ThemeSet(mode: PaletteMode): ThemeOptions {
  return {
    ...getDesignTokens(mode),
    components: {
      ...chartsCustomizations,
      ...dataGridCustomizations,
      ...datePickersCustomizations,
      ...treeViewCustomizations,
      ...inputsCustomizations,
      ...inputsCustomizations,
      ...dataDisplayCustomizations,
      ...feedbackCustomizations,
      ...navigationCustomizations,
      ...surfacesCustomizations,
    },
  };
}
