import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import Sitemark from "./SitemarkIcon";
import { MProps, Func } from "./modes";

import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, ListItemIcon, Menu, Tooltip } from "@mui/material";
import ContextA from "src/auth/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
export function Search() {
  return (
    <FormControl sx={{ width: { xs: "100%", md: "25ch" } }} variant="outlined">
      <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1, maxWidth: "80%" }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: "text.primary" }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "search",
        }}
      />
    </FormControl>
  );
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));

export function User() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const flys = useNavigate();
  const conx = React.useContext(ContextA);
  const stat = conx.State;
  const icon = stat.data.icon;
  function prof() {
    setAnchorEl(null);
    flys("/user/data");
  }
  function redo() {
    setAnchorEl(null);
    flys("/user/file");
  }
  function logo() {
    setAnchorEl(null);
    conx.SignO("/user/sign");
  }
  const sign = (
    <Link to={"/user/sign"}>
      <IconButton>
        <MenuIcon />
      </IconButton>
    </Link>
  );
  const menu = (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          // size="small"
          // sx={{ ml: 2 }}
          // aria-controls={open ? "account-menu" : undefined}
          // aria-haspopup="true"
          // aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }} src={icon} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={prof}>
          <AccountBoxIcon />
          <h5>Profile</h5>
        </MenuItem>
        <MenuItem onClick={redo}>
          <AccountBoxIcon />
          <h5>Edit Profile</h5>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logo}>
          <LogoutIcon />
          <h5>Logout</h5>
        </MenuItem>
      </Menu>
    </>
  );
  // return stat.type ? menu : sign;
  return stat.type ? menu : menu;
}

export default function Menus(data: MProps) {
  const [open, setOpen] = React.useState(false);
  const utype = React.useContext(ContextA).State.type;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 1,
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>

          <Search />
          <User />

          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                  <Func {...data} />
                </Box>

                <Divider sx={{ my: 3 }} />
                <MenuItem>
                  <Link to={"/"}>
                    <Button color="primary" variant="contained" fullWidth>
                      My Post (todo)
                    </Button>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/"}>
                    <Button color="primary" variant="contained" fullWidth>
                      My friend (todo)
                    </Button>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/"}>
                    <Button color="primary" variant="contained" fullWidth>
                      My marks (todo)
                    </Button>
                  </Link>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
