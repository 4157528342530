import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
import { post, repl, comu } from "src/proto/code_pb";
import { NavigateFunction } from "react-router-dom";
import { ComuItem, ReplItem, PostItem, PostRepl, ComuDetail } from "./items";
export function ComuList(data: comu[], func: NavigateFunction) {
  const handleClick = () => {
    console.info("You clicked the filter chip.");
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Typography variant="h2" gutterBottom>
        Comu List
      </Typography>

      <Grid container spacing={2} columns={12}>
        {data.map((item, _) => ComuItem(item, func))}
      </Grid>
    </Box>
  );
}
export function ReplList(post: post, data: repl[]) {
  const handleClick = () => {
    console.info("You clicked the filter chip.");
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          width: "100%",
          justifyContent: "space-between",
          alignItems: { xs: "start", md: "center" },
          gap: 4,
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            gap: 3,
            overflow: "auto",
          }}
        >
          <Chip onClick={handleClick} size="medium" label="All categories" />
          <Chip
            onClick={handleClick}
            size="medium"
            label="Company"
            sx={{
              backgroundColor: "transparent",
              border: "none",
            }}
          />
          <Chip
            onClick={handleClick}
            size="medium"
            label="Product"
            sx={{
              backgroundColor: "transparent",
              border: "none",
            }}
          />
          <Chip
            onClick={handleClick}
            size="medium"
            label="Design"
            sx={{
              backgroundColor: "transparent",
              border: "none",
            }}
          />
          <Chip
            onClick={handleClick}
            size="medium"
            label="Engineering"
            sx={{
              backgroundColor: "transparent",
              border: "none",
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "row",
            gap: 1,
            width: { xs: "100%", md: "fit-content" },
            overflow: "auto",
          }}
        >
          {/* <Search /> */}
          <IconButton size="small" aria-label="RSS feed">
            <RssFeedRoundedIcon />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={2} columns={12}>
        {PostRepl(post)}
        {data.map((item, _) => ReplItem(item))}
      </Grid>
    </Box>
  );
}
export function PostList(data: post[], func: NavigateFunction) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Typography variant="h2" gutterBottom>
        Post List
      </Typography>

      <Grid container spacing={2} columns={12}>
        {data.map((item, _) => PostItem(item, func))}
      </Grid>
    </Box>
  );
}
export function CoPoList(cata: comu, pata: post[], func: NavigateFunction) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Grid container spacing={2} columns={12}>
        {ComuDetail(cata, func)}
        {pata.map((item, _) => PostItem(item, func))}
      </Grid>
    </Box>
  );
}
function Chips() {
  const handleClick = () => {
    console.info("You clicked the filter chip.");
  };
  const chiptag = (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        width: "100%",
        justifyContent: "space-between",
        alignItems: { xs: "start", md: "center" },
        gap: 4,
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          gap: 3,
          overflow: "auto",
        }}
      >
        <Chip onClick={handleClick} size="medium" label="All categories" />
        <Chip
          onClick={handleClick}
          size="medium"
          label="Company"
          sx={{
            backgroundColor: "transparent",
            border: "none",
          }}
        />
        <Chip
          onClick={handleClick}
          size="medium"
          label="Product"
          sx={{
            backgroundColor: "transparent",
            border: "none",
          }}
        />
        <Chip
          onClick={handleClick}
          size="medium"
          label="Design"
          sx={{
            backgroundColor: "transparent",
            border: "none",
          }}
        />
        <Chip
          onClick={handleClick}
          size="medium"
          label="Engineering"
          sx={{
            backgroundColor: "transparent",
            border: "none",
          }}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "row",
          gap: 1,
          width: { xs: "100%", md: "fit-content" },
          overflow: "auto",
        }}
      >
        {/* <Search /> */}
        <IconButton size="small" aria-label="RSS feed">
          <RssFeedRoundedIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
