import { po } from "./envs";
import {
  post,
  repl,
  user,
  comu,
  vote,
  mark,
  reqs,
  resp,
} from "../proto/code_pb";
import { useEffect, useState } from "react";
import * as grpcWeb from "grpc-web";

function HandsErr(err: grpcWeb.RpcError) {
  console.log(err);
  return "";
}

export function SetaPostPood(post: post) {
  function Process(err: grpcWeb.RpcError, res: resp) {
    if (err) HandsErr(err);
    else {
      console.log(res);
    }
  }
  po.setaPostPood(post, {}, Process);
}
export function SetaReplRood(repl: repl) {
  function Process(err: grpcWeb.RpcError, res: resp) {
    if (err) HandsErr(err);
    else {
      console.log(res);
    }
  }
  po.setaReplRood(repl, {}, Process);
}
export function useGetaPostPood(reqs: reqs) {
  let data = new post();
  const [item, setItem] = useState(data);

  function Process(err: grpcWeb.RpcError, res: post) {
    if (err) HandsErr(err);
    else setItem(res);
  }
  function Posts() {
    po.getaPostPood(reqs, {}, Process);
  }
  useEffect(Posts, []);
  return item;
}

export function useGetsPostCood(reqs: reqs) {
  let datas: post[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    console.log("finally did");
    setEnds(true);
  }
  function Data(res: post) {
    datas.push(res);
    setItem(datas);
  }
  function Posts() {
    setEnds(false);
    let stream = po.getsPostCood(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Posts, []);
  return item;
}

export function useGetsPostMark(reqs: reqs) {
  let datas: post[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    console.log("finally did");
    setEnds(true);
  }
  function Data(res: post) {
    datas.push(res);
    setItem(datas);
  }
  function Posts() {
    setEnds(false);
    let stream = po.getsPostMark(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Posts, []);
  return item;
}

export function GetsReplRood(reqs: reqs) {
  let datas: repl[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    console.log("finally did");
    setEnds(true);
  }
  function Data(res: repl) {
    datas.push(res);
    setItem(datas);
  }
  function Posts() {
    setEnds(false);
    let stream = po.getsReplRood(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  // useEffect(Posts, []);
  Posts();
  return item;
}
export function useGetsReplRood(reqs: reqs) {
  let datas: repl[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    console.log("finally did");
    setEnds(true);
  }
  function Data(res: repl) {
    datas.push(res);
    setItem(datas);
  }
  function Posts() {
    setEnds(false);
    let stream = po.getsReplRood(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Posts, []);
  return item;
}
export function useGetsPostFeed(reqs: reqs) {
  let datas: post[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    console.log("finally did");
    setEnds(true);
  }
  function Data(res: post) {
    datas.push(res);
    setItem(datas);
  }
  function Posts() {
    setEnds(false);
    let stream = po.getsPostFeed(reqs, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Posts, []);
  return item;
}
