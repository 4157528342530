import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider, PaletteMode } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

import { AuthContextProvider } from "src/auth/auth";
import { ContextProvider } from "src/auth";
import Theme from "src/style";
import { MProps } from "./modes";
import Menus from "./menus";
import Navis from "./navis";
import Foots from "./foots";
// import Route from "./route";
import Route from "src/routes";
export default function App() {
  const [mode, setm] = React.useState<PaletteMode>("light");
  const [vals, sets] = React.useState(true);
  const Style1 = createTheme(Theme(mode));
  const Style2 = createTheme({ palette: { mode } });
  const setmode = () => {
    const moden = mode === "dark" ? "light" : "dark";
    setm(moden);
    // localStorage.setItem("themeMode", moden); // Save the selected moden to localStorage
  };
  const setheme = () => {
    sets((prev) => !prev);
  };
  const data: MProps = {
    mode: mode,
    mods: setmode,
    them: vals,
    thes: setheme,
  };
  return (
    <Router>
      <AuthContextProvider>
        <ThemeProvider theme={vals ? Style1 : Style2}>
          <CssBaseline enableColorScheme />

          {/* <A /> */}
          <Menus {...data} />
          <Container
            maxWidth="lg"
            component="main"
            sx={{ display: "flex", flexDirection: "column", my: 16, gap: 4 }}
          >
            <Route />
          </Container>
          <Navis />
          {/* <Foots /> */}
        </ThemeProvider>
      </AuthContextProvider>
    </Router>
  );
}
