import * as React from "react";
import IconButton from "@mui/material/IconButton";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import AdbIcon from "@mui/icons-material/Adb";
import AppleIcon from "@mui/icons-material/Apple";
import {
  createTheme,
  ThemeProvider,
  PaletteMode,
  styled,
} from "@mui/material/styles";
export interface MProps {
  them: boolean;
  thes: () => void;
  mode: PaletteMode;
  mods: () => void;
}

export function Func(data: MProps) {
  return (
    <div>
      <IconButton
        onClick={data.mods}
        color="primary"
        size="small"
        aria-label="Theme toggle button"
      >
        {data.mode === "dark" ? (
          <ModeNightRoundedIcon fontSize="small" />
        ) : (
          <WbSunnyRoundedIcon fontSize="small" />
        )}
      </IconButton>
      <IconButton
        onClick={data.thes}
        color="primary"
        size="small"
        aria-label="Theme toggle button"
        //   {...props}
      >
        {data.them ? (
          <AppleIcon fontSize="small" />
        ) : (
          <AdbIcon fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}
