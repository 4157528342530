import {
  createContext,
  Reducer,
  useReducer,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

export type UData = {
  type: boolean;
  user: string;
  name: string;
  mail: string;
  icon: string;
  memo: string;
};

export interface ContextAuth {
  State: UData | null;
  SignI: (para: UData) => void;
  SignO: () => void;
  Flyto: (para: string) => void;
}
const Context = createContext<ContextAuth>({
  State: null,
  SignI: () => {},
  SignO: () => {},
  Flyto: () => {},
});

type AuthProps = {
  children: React.ReactElement;
};
export const ContextProvider = (props: AuthProps) => {
  const { children } = props;
  const [cox, sox] = useState<UData | null>(null);
  const fly = useNavigate();
  const aux = useMemo(() => {
    return {
      State: cox,
      SignI: (para: UData) => {
        sox(para);
        console.log(cox);
      },
      SignO: () => {
        sox(null);
      },
      Flyto: (para: string) => {
        fly(para);
      },
    };
  }, [cox]);
  return <Context.Provider value={aux}>{children}</Context.Provider>;
};
export default Context;
