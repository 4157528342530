import { Routes, Route } from "react-router-dom";
import { ChatHist, ChatList } from "src/chat";
// import { CF, PT, SD, RP, CK, PD, PS, CM, CR, RDP, CL, CPA } from "src/post";
import { IP, IC, RP, RC, SR, PD, CD, PF, CL } from "src/post";
import RG from "src/auth/reeg";
import SG from "src/auth/loog";
import { UF, UD } from "src/auth/user";
import SS from "src/app/items";
// import YY from "src/app/tools";
export default function Routex() {
  return (
    <Routes>
      <Route path="/" Component={SS} />
      <Route path="chat">
        <Route path="list" Component={ChatList} />
        <Route path="hist" Component={ChatHist} />
      </Route>
      <Route path="post">
        <Route path="init" Component={IP} />
        <Route path="redo" Component={RP} />
        <Route path="repl" Component={SR} />
        <Route path="data" Component={PD} />
        <Route path="feed" Component={PF} />
        <Route path="mark" Component={SS} />
      </Route>
      <Route path="comu">
        <Route path="init" Component={IC} />
        <Route path="redo" Component={RC} />
        <Route path="data" Component={CD} />
        <Route path="list" Component={CL} />
        <Route path="mark" Component={SS} />
      </Route>
      <Route path="user">
        <Route path="sign" Component={SG} />
        <Route path="regs" Component={RG} />
        <Route path="data" Component={UD} />
        <Route path="file" Component={UF} />
      </Route>
    </Routes>
  );
}
