import * as ReactDOM from "react-dom";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
// import LandingPage from "./test/LandingPage";
// import Apps from "./test/apps";
import Y from "src/app";
// const App = () => <Apps />;
const App = () => <Y />;
// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
const roos = document.getElementById("root")!;
const root = createRoot(roos);
root.render(<App />);
