import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { user, chat } from "src/proto/code_pb";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Content } from "src/quill";

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const TitleTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  textDecoration: "none",
  "&:hover": { cursor: "pointer" },
  "& .arrow": {
    visibility: "hidden",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  "&:hover .arrow": {
    visibility: "visible",
    opacity: 0.7,
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "3px",
    borderRadius: "8px",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    width: 0,
    height: "1px",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.text.primary,
    opacity: 0.3,
    transition: "width 0.3s ease, opacity 0.3s ease",
  },
  "&:hover::before": {
    width: "100%",
  },
}));

function ChatUser(item: chat) {
  const data = item.toObject();
  const date = new Date(data.reti * 1000);
  const time = date.toLocaleString();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <AvatarGroup max={3}>
          <Avatar
            alt={data.uame}
            src={data.ucon}
            sx={{ width: 24, height: 24 }}
          />
        </AvatarGroup>
        <Typography variant="caption">{data.uame}</Typography>
      </Box>
      <Typography variant="caption">{time}</Typography>
    </Box>
  );
}
function ChatItem(item: chat, func: NavigateFunction) {
  const data = item.toObject();
  function f() {
    func("/chat/hist", { state: data.food });
  }
  return (
    <Grid size={{ xs: 50, md: 12 }} offset={{ md: 2 }}>
      {/* <Grid key={index} size={{ xs: 50, md: 12 }} offset={{ md: 8 }}> */}

      <Card>
        <CardActionArea onClick={f}>
          <CardContent>{Content(data.word)}</CardContent>
        </CardActionArea>
        <CardActions>{ChatUser(item)}</CardActions>
      </Card>

      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 1,
          height: "100%",
        }}
      >
        <Typography gutterBottom variant="caption" component="div">
          {data.word}
        </Typography>
        <TitleTypography
          gutterBottom
          variant="h6"
          //   onFocus={() => handleFocus(index)}
          //   onBlur={handleBlur}
          tabIndex={0}
          //   className={focusedCardIndex === index ? "Mui-focused" : ""}
        >
          {"article.title"}
          <NavigateNextRoundedIcon
            className="arrow"
            sx={{ fontSize: "1rem" }}
          />
        </TitleTypography>
        <StyledTypography variant="body2" color="text.secondary" gutterBottom>
          {data.word}
        </StyledTypography>
        {ChatUser(item)}
      </Box> */}
    </Grid>
  );
}

export function ChatList(data: chat[], func: NavigateFunction) {
  return (
    <div>
      <Typography variant="h2" gutterBottom>
        Chat List
      </Typography>
      <Grid container spacing={5} columns={20}>
        {data.map((item, _) => ChatItem(item, func))}
      </Grid>
      {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
        <Pagination
          hidePrevButton
          hideNextButton
          count={10}
          boundaryCount={10}
        />
      </Box> */}
    </div>
  );
}

export default function La() {
  const data: chat[] = [];
  const item1 = new chat();
  const item2 = new chat();
  const item3 = new chat();
  const uood = "my";
  item1.setWord("hi");
  item1.setFood(uood);
  item2.setWord("too");
  item2.setFood("nob");
  item3.setWord("hi , again");
  item3.setFood(uood);
  data.push(item1);
  data.push(item2);
  data.push(item3);
  // return ChatList(data);
  return;
}
