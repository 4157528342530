// import { Send } from "./forms";
// import { NewPost, RedoPost } from "./check";
// import { PostDemo, PostData, ComuDemo, ComuPage } from "./datas";

// import { ComuF, ComuR } from "./comck";
// import { ComuForm, PostForm } from "./ccccc";
import * as s from "src/post/datas";
export function IP() {
  return s.InitPost();
}
export function RP() {
  return s.RedoPost();
}
export function IC() {
  return s.InitComu();
}
export function RC() {
  return s.RedoComu();
}
export function SR() {
  return s.SendRepl();
}
export function PD() {
  return s.ReplPage();
}
export function CD() {
  return s.ComuData();
}
export function PF() {
  return s.PostPage();
}
export function CL() {
  return s.ComuPage();
}
