// @ts-nocheck
import { cs } from "./envs";
import { chat, resp } from "../proto/code_pb";
import { useEffect, useState } from "react";
import * as grpcWeb from "grpc-web";

function HandsErr(err: grpcWeb.RpcError) {
  console.log("funck ya");
  console.log(err);
  return "";
}
export function SendUserChat(chat: chat) {
  function Process(err: grpcWeb.RpcError, res: resp) {
    if (err) HandsErr(err);
    else {
      console.log(res);
    }
  }
  cs.sendUserChat(chat, {}, Process);
}
// export function useSendUserChat(chat: chat) {
//   let data = new resp();
//   const [item, setItem] = useState(data);

//   function Process(err: grpcWeb.RpcError, res: resp) {
//     if (err) HandsErr(err);
//     else {
//       console.log(res);
//       setItem(res);
//     }
//   }
//   function Chats() {
//     cs.sendUserChat(chat, {}, Process);
//   }
//   // useEffect(Chats, []);
//   // return item;
//   return useEffect(Chats, []);
// }

export function useGetsUserChat(r: reqs) {
  let datas: chat[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    setEnds(true);
  }
  function Data(res: chat) {
    datas.push(res);
    setItem(datas);
  }
  function Chats() {
    setEnds(false);
    let stream = cs.getsUserChat(r, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Chats, []);
  return item;
}
export {};

export function useGetsChatUood(r: reqs) {
  let datas: chat[] = [];

  const [item, setItem] = useState(datas);
  const [ends, setEnds] = useState(false);
  async function Ends() {
    setEnds(true);
  }
  function Data(res: chat) {
    datas.push(res);
    setItem(datas);
  }
  function Chats() {
    setEnds(false);
    let stream = cs.getsChatUood(r, {});
    stream.on("data", Data);
    stream.on("end", Ends);
    stream.on("error", HandsErr);
    return () => {
      stream.cancel();
    };
  }
  useEffect(Chats, []);
  return item;
}
export {};
