import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { IconButton, Dialog } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  styled,
  PaletteMode,
} from "@mui/material/styles";
// import ForgotPassword from "./ForgotPassword";
// import getSignInTheme from "./theme/getSignInTheme";
// import { GoogleIcon, FacebookIcon, SitemarkIcon } from "./CustomIcons";
// import NavBar from "./NavBar";
import { useEffect, useState, useContext, FormEventHandler } from "react";
import ContextA from "src/auth/auth";
import { State } from "src/auth/auth";
import { useNavigate } from "react-router-dom";
import { reqs, user } from "src/proto/code_pb";
import {
  InitUserUood,
  SetaUserUood,
  useGetaUserUood,
  useSetaUserUood,
} from "src/apis/user";
import { UserForm, UserData } from "src/post/forms";
export function UF() {
  const { State, SignO } = useContext(ContextA);
  if (!State.type) SignO("/user/sign");
  const r = new reqs();
  r.setUood(State.data.user);
  const u = useGetaUserUood(r);
  return UserForm(u);
}
export function UD() {
  const { State, SignO } = useContext(ContextA);
  if (!State.type) SignO("/user/sign");
  const r = new reqs();
  r.setUood(State.data.user);
  const u = useGetaUserUood(r);
  return UserData(u);
}
// function Fil() {
//   var reader = new FileReader();
//   var file = fileList[0]; //这里fileList是通过 File API 获取的文件数组（开启multi）
//   reader.readAsDataURL(file);
//   var base64 = reader.result as string;
// }

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "auto",
  backgroundImage:
    "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("sm")]: {
    height: "100dvh",
  },
  ...theme.applyStyles("dark", {
    backgroundImage:
      "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
  }),
}));

function Lines(
  hold: string,
  func?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
) {
  return (
    <TextField
      placeholder={hold}
      onChange={func}
      autoFocus
      required
      multiline
      margin="normal"
      fullWidth
      variant="standard"
    />
  );
}
