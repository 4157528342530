import ContextA, { UserData } from "src/auth/auth";
import { ChatData as CD } from "./hists";
import { ChatList as CL } from "./lists";
import { chat, reqs } from "src/proto/code_pb";
import React from "react";
import { useGetsChatUood, useGetsUserChat } from "src/apis/chat";
import { useNavigate } from "react-router-dom";

export function ChatList() {
  const { State, SignO } = React.useContext(ContextA);
  if (!State.type) SignO("/user/sign");
  const user = localStorage.getItem("user") || "";
  if (!user) SignO("/user/sign");
  const uata: UserData = JSON.parse(user);
  const uood = State.data.user;
  const r = new reqs();
  r.setUood(uata.user);
  console.log(r.toObject());
  const data = useGetsChatUood(r);
  console.log(data);
  const fly = useNavigate();

  return CL(data, fly);
}
export function ChatHist() {
  return <CD />;
}
